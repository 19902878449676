import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PdfPaginationService {

  private totalPagesCount: number = 0
  private currentViewPage?: string = undefined
  private prependPages: number = 0
  private prependAdPages: number = 0
  private appendAdPages: number = 0
  private currentPageIndex: number = 0
  private pages: any[] = []

  private currentViewPageSubject = new BehaviorSubject<string>('1')
  private currentPageIndexSubject = new BehaviorSubject<number>(0)
  private pagesSubject = new BehaviorSubject<string[]>([])

  init(
    currentViewPage: string | undefined = undefined,
    totalPagesCount: number = 0,
    prependPages: number = 0,
    prependAdPages: number = 0,
    appendAdPages: number = 0
  ): void {
    this.currentViewPage = currentViewPage
    this.totalPagesCount = totalPagesCount
    this.prependPages = prependPages
    this.prependAdPages = prependAdPages
    this.appendAdPages = appendAdPages

    this.buildPages()
    this.setCurrentViewPage(currentViewPage)
  }

  public setCurrentViewPage(viewPage?: string) {

    let pageIndex = 0
    this.pages.forEach((page, index) => {
      if (page == viewPage) pageIndex = index
    })

    if (! viewPage) this.currentViewPage = this.pages[0] + ''
    else this.currentViewPage = viewPage

    if (! this.currentViewPage) this.currentViewPage = "1"

    this.currentPageIndex = pageIndex
    this.currentPageIndexSubject.next(pageIndex)
    this.currentViewPageSubject.next(this.currentViewPage)
  }

  public setCurrentPageIndex(index: number) {
    this.currentPageIndex = index
    this.currentViewPage = this.pages[index] + ''
    this.currentViewPageSubject.next(this.currentViewPage)
    this.currentPageIndexSubject.next(index)
  }

  public getCurrentViewPageSubject() {
    return this.currentViewPageSubject
  }

  public getCurrentPageIndexSubject() {
    return this.currentPageIndexSubject
  }

  public getPagesSubject() {
    return this.pagesSubject
  }

  private buildPages() {
    let numberedPagesCount = this.totalPagesCount
    let firstPage = 1
    this.pages = []
    if (this.prependAdPages) {
      numberedPagesCount -= this.prependAdPages
      let i = 1
      while (i <= this.prependAdPages) {
        this.pages.push('A'+i)
        i++
      }
    }

    if (this.appendAdPages) {
      numberedPagesCount -= this.appendAdPages
    }

    if (this.prependPages) {
      firstPage+= this.prependPages
    }

    let i = 0
    while (i < numberedPagesCount) {
      this.pages.push(i+firstPage)
      i++
    }

    if (this.appendAdPages) {
      let i = 0
      while (i < this.appendAdPages) {
        this.pages.push('E' + i)
        i++
      }
    }

    this.pagesSubject.next(this.pages)
  }
}
