import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktPriceTypeRecordDataInterface extends RecordDataInterface {
  price_type_key: number
  price_type_name: string
}

export interface WasmuthFktPriceTypeRecordInterface extends RecordInterface<WasmuthFktPriceTypeRecordDataInterface> {
}

export const WasmuthFktPriceTypeModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Preistyp",
  titleSingular: "Preistyp",
  serviceSlug: "wasmuth_fkt_price_types",
  fields: [
    { type: 'id', title: '#', prop: 'price_type_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'price_type_name', isNameProperty: true },
  ]
}
