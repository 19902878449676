<nav class="navbar">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="[ '/' ]"><img src="assets/logo.svg" alt="wefra logo" title="WEFRA LIFE"/></a>
    <ul class="navbar-nav" *ngIf="authenticated">
      <li *ngIf="currentCaptureRecord" class="nav-item">
        <a href="{{ '/ad-spendings/capture/' + currentCaptureRecord.data.edition_id }}" class="nav-link pointer">
          <i-bs name="clipboard2"></i-bs>
          <span>Aktuelle Erfassung</span>
        </a>
      </li>

      <li class="nav-item">
        <span class="nav-link pointer" (click)="logout()">
          <i-bs name="box-arrow-right"></i-bs>
          <span>Logout</span>
        </span>
      </li>
      <li class="nav-item" *ngIf="userIsAllowedToExport">
        <ng-container *ngIf="! exportReady; else downloadExport">
          <span class="nav-link pointer" (click)="jsonExport()">
            <i-bs *ngIf="! jsonExportLoading" class="export" name="filetype-json"></i-bs>
            <i-bs *ngIf="jsonExportLoading" class="loader" name="arrow-clockwise"></i-bs>
            <span>Export Database</span>
          </span>
        </ng-container>
        <ng-template #downloadExport>
          <a class="nav-link pointer export-ready" href="{{ downloadLink }}" (click)="exportReady = false; downloadLink = undefined">
            <i-bs name="file-earmark-arrow-down"></i-bs>
            <span>Download Export</span>
          </a>
        </ng-template>
      </li>
    </ul>
  </div>
</nav>

