import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface MagazineRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  mediatype_id: number
  targetaudiences_id: number
  publisher_id: number
  wasmuth_id: number
  valid_since: string
  valid_till: string
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
  notice?: string
}

export interface MagazineRecordInterface extends RecordInterface<MagazineRecordDataInterface> {
}

export const MagazineModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "magazines",
  allowExportXlsx: true,
  title: "Magazine",
  titleSingular: "Magazin",
  allowHistoricize: true,
  defaultSort: [{ field: 'name', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { type: "text", title: 'Notice', prop: 'notice', validators: [ ]},
    { prop: 'mediatype_id', type: "constraint", title: 'Medientyp', constraint: { table: 'media_types' }, validators: [ Validators.required ]},
    { prop: 'targetaudiences_id', type: "constraint", title: 'Zielgruppe', constraint: { table: 'target_audiences' }, validators: [ Validators.required ]},
    { prop: 'publisher_id', type: "constraint", title: 'Verlag', constraint: { table: 'publishers' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_id', type: "constraint", title: 'Wasmuth Ratecard', constraint: { table: 'wasmuth_ratecards' }},
    { prop: 'valid_since', type: "datestring", title: "Gültig ab", validators: [ Validators.required ]},
    { prop: 'valid_till', type: "datestring", title: "Gültig bis"},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "datestring", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "datestring", title: 'Bearbeitet am', edit: false},
  ]
}

