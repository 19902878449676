import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  WefraPubdateModelConfiguration,
  WefraPubdateRecordDataInterface,
  WefraPubdateRecordInterface
} from "../models/wefra_pubdate";
import { HttpWefraPubdateService } from "../services/http/wefra_pubdate.service";

@Component({
  selector: 'wefra-pubdates-component',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class WefraPubdatesComponent extends AbstractRecordComponent<WefraPubdateRecordInterface, WefraPubdateRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = WefraPubdateModelConfiguration
  api = inject(HttpWefraPubdateService)

  constructor(
  ) {
    super()
  }
}
