import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { renderDateFromString } from "../helpers/main";

export interface WasmuthMediaRatecardRecordDataInterface extends RecordDataInterface {
  medium_id: number
  ratecard_id: number
  publisher_id: number
  ratecard_number: string
  name1: string
  name_long: string
  name2: string
  initial: string
  edition: string
  edition_long: string
  datetime: string
  country_code: string
}

export interface WasmuthMediaRatecardRecordInterface extends RecordInterface<WasmuthMediaRatecardRecordDataInterface> {
}

export const WasmuthMediaRatecardModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Medien Ratecards",
  titleSingular: "Wasmuth Medien Ratecards",
  serviceSlug: "wasmuth_media_ratecards",
  defaultFilter: { valid_since: new Date().getFullYear() },
  fields: [
    { prop: 'medium_id', type: "constraint", title: 'Medium', constraint: { table: 'wasmuth_media' }},
    { prop: 'publisher_id', type: "constraint", title: 'Publisher', constraint: { table: 'wasmuth_publishers' }},
    { prop: 'ratecard_id', type: 'text', title: 'RC ID' },
    { prop: 'ratecard_number', type: 'text', title: 'Ratecard Number' },
    { type: "text", title: 'Name', prop: 'name1', isNameProperty: true },
    { type: "text", title: 'Name Lang', prop: 'name_long' },
    { type: "text", title: 'Name 2', prop: 'name2' },
    { type: "text", title: 'Initial', prop: 'initial' },
    { type: "text", title: 'Edition', prop: 'edition' },
    { type: "text", title: 'Edition Lang', prop: 'edition_long' },
    { type: "datetime", title: 'Letzte Änderung', prop: 'modifydate' },
    // { type: "int", title: "Auflage Print", prop: 'circulation_print' },
    { type: "virtual", subType: "date", title: "Gültig ab", prop: 'valid_since', disableSort: true, renderer: function(record) {
        if (record.resolved["ratecard_number"]?.data["valid_since"]) {
          return renderDateFromString(record.resolved["ratecard_number"]?.data["valid_since"])
        }
        return ''
    }, filterProperty: "ratecardNumber.valid_since", disableSearch: true },
    { type: "virtual", subType: "date", title: "Gültig bis", prop: 'valid_till', disableSort: true, renderer: function(record) {
      if (record.resolved["ratecard_number"]?.data["valid_till"]) {
        return renderDateFromString(record.resolved["ratecard_number"]?.data["valid_till"])
      }
      return ''
    }, filterProperty: "ratecardNumber.valid_till", disableSearch: true }
  ]
}

