import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface WefraRatecardRecordDataInterface extends RecordDataInterface {
  id: number
  valid_from: string
  valid_to: string
  magazine_id: number
}

export interface WefraRatecardRecordInterface extends RecordInterface<WefraRatecardRecordDataInterface> {
}

export const WefraRatecardModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "wefra_ratecards",
  allowExportXlsx: true,
  title: "Wefra Ratecards",
  titleSingular: "Wefra Ratecard",
  defaultSort: [],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'magazine_id', renderer: (record: RecordInterface<RecordDataInterface>) => {
        return record.resolved["magazine_id"]?.data["name"]
      }, type: "constraint", title: 'Magazin', constraint: { table: 'magazines' },
      validators: [ Validators.required ], filterProperty: "magazineId.name", isNameProperty: true
    },
    { prop: 'valid_from', type: "date", title: "Gültig ab", validators: [ Validators.required ]},
    { prop: 'valid_to', type: "date", title: "Gültig bis"},
  ]
}

