import { Inject, Injectable } from '@angular/core';
import { HttpMonitorService } from "./http/monitor.service";
import { LocalStorageService } from "./localstorage.service";
import { MyDate } from "../helpers/main";
import { Observable } from "rxjs";
import { MagazineListResponseItemInterface } from "../models/main";

export interface MonitorSearchParameters {
  from: MyDate
  to: MyDate
  magazine?: string
  publisher?: string
  withAliases: string
}

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  private _cache: Map<string, string>

  constructor(
    @Inject(HttpMonitorService) private api: HttpMonitorService,
    @Inject(LocalStorageService) private localStorage: LocalStorageService,
  ) {
    this._cache = new Map<string, string>()
  }
    getMagazineList() {

      // const slug = "monitor_magazine_list"
      //
      // // check cache
      // if (this._cache.has(slug)) {
      //   return new Observable<MagazineListResponseItemInterface[]>(subscriber => {
      //     const result = JSON.parse(<string>this._cache.get(slug))
      //     subscriber.next(result)
      //   })
      // }
      //
      // // check localstorage
      // let lsResult = this.localStorage.get(slug)
      // if (lsResult) {
      //   return new Observable<MagazineListResponseItemInterface[]>(subscriber => {
      //     subscriber.next(lsResult)
      //   })
      // }

      let obs = this.api.getMagazineList()

      return new Observable<MagazineListResponseItemInterface[]>(subscriber => {
        obs.subscribe(response => {
          // this._cache.set(slug, JSON.stringify(response))
          // this.localStorage.set(slug, response)
          subscriber.next(response)
        }, error => {
          subscriber.error()
        })
      })
    }

    getMonitorData(params: MonitorSearchParameters) {
      return this.api.getMonitorData(params)
    }
}
