import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktPageFormatCodeRecordDataInterface extends RecordDataInterface {
  page_format_code: string
  is_default: number
  page_numerator: number
  page_denominator: number
  numeric_value: number
}

export interface WasmuthFktPageFormatCodeRecordInterface extends RecordInterface<WasmuthFktPageFormatCodeRecordDataInterface> {
}

export const WasmuthFktPageFormatCodeModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Seitenformat",
  titleSingular: "Seitenformat",
  serviceSlug: "wasmuth_fkt_page_format_codes",
  fields: [
    { type: 'id', title: '#', prop: 'page_format_code', isIdProperty: true, isNameProperty: true },
    { prop: 'is_default', type: "bool", title: 'Ist Standard' },
    { prop: 'page_numerator', type: "int", title: 'page_numerator' },
    { prop: 'page_denominator', type: "int", title: 'page_denominator' },
    { prop: 'numeric_value', type: "int", title: 'numeric_value' },
  ]
}




