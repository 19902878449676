import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import {
  CrudGridPersistentInterface,
  FiltersInterface,
  GridActionsInterface,
  KeyValueInterface,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface,
  SearchCriteriasInterface,
  SortDirections,
  SortingInterface
} from "../models/main";
import { HttpAbstractRecordService } from "../services/http/record.service.abstract";
import { LocalStorageService } from "../services/localstorage.service";
import { ModelConfigFactory } from "../models/factory";
import { Subject } from "rxjs";
import { HttpServiceFactory } from "../services/http/factory";
import { ModalService } from "../services/modal.service";
import { CrudGridDeleteComponent } from "./delete.component";
import { ToastService } from "../services/toast.service";
import { CrudGridReplaceComponent } from "./replace.component";
import { CrudGridHistoricizeComponent } from "./historicize.component";
import { CrudGridHistoricizePreviewComponent } from "./historicize/preview.component";
import { BasicGridComponent } from "../basic-grid/baslc-grid.component";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'wefra-crud-grid',
  templateUrl: './crud-grid.component.html',
})
export class CrudGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface> extends BasicGridComponent<T, K> implements OnInit {

  totalRecordsCount: number = 0
  currentPage: number = 1
  lastPage: number = 1
  pageSizes: number[] = [20, 50, 100, 250, 500, 1000, 99999]
  userIsAllowedToExport = false

  @Input() public hideControls: boolean = false
  @Input() public currentPageSize: number = 20
  @Input() public api?: HttpAbstractRecordService<T, K>
  @Input() public selectOnly: boolean = false
  @Input() public showAddRecordButton: boolean = false
  @Input() public presetFilters?: FiltersInterface
  @Input() public additionalActions?: GridActionsInterface[]
  @Input() public allowMultiSelect: boolean = false
  @Input() public gridReloadSubject?: Subject<boolean>
  @Input() public actions?: GridActionsInterface[]
  @Input() public additionalQueryParams?: KeyValueInterface

  /**
   * if this property is set, the filters, pagination and sort will be
   * saved in localstorage and loaded on next call
   *
   * @private
   */
  @Input() public persistentStateKey?: string

  @Output("editRecord") editRecordEvent = new EventEmitter<T>()
  @Output("viewRecord") viewRecordEvent = new EventEmitter<T>()
  @Output("deleteRecord") deleteRecordEvent = new EventEmitter<T>()
  @Output("selectRecords") selectRecordsEvent = new EventEmitter<T[]>()
  @Output("newRecord") newRecordEvent = new EventEmitter<K>()

  //@ViewChild('tableWrap') tableWrap!: ElementRef<HTMLDivElement>

  sortRegistry: SortingInterface[] = []
  filterRegistry: FiltersInterface = {}

  selectionRegistry: string[] = []
  multiSelectMode: boolean = false
  idProperty: string = "id"

  @HostListener("document:keydown", ["$event"]) onKeyDown(event: KeyboardEvent) {
    if (! this.allowMultiSelect) return
    if (event.key == "Alt") {
      if (this.selectOnly) {
        this.multiSelectMode = true
      }
    }
  }

  @HostListener("document:keyup", ["$event"]) onKeyUp(event: KeyboardEvent) {
    if (! this.allowMultiSelect) return
    if (event.key == "Alt") {
      if (this.selectOnly) {
        this.multiSelectMode = false
        if (this.selectionRegistry.length) {
          this.onMultiSelect()
        }
      }
    }
  }

  constructor(
    @Inject(LocalStorageService) public localStorageService: LocalStorageService,
    @Inject(HttpServiceFactory) public httpServiceFactory: HttpServiceFactory,
    @Inject(ModalService) public modalService: ModalService,
    @Inject(ToastService) public toastService: ToastService,
    @Inject(AuthService) authService: AuthService,
  ) {
    super(
      authService
    )
  }

  getCurrentSearchCriterias(): SearchCriteriasInterface {
    return {
      sorting: this.sortRegistry,
      filters: this.filterRegistry,
      paging: {
        page: this.currentPage,
        size: this.currentPageSize
      }
    }
  }

  load() {
    if (this.api) {
      this.isLoading = true
      this.api.list(this.getCurrentSearchCriterias(), this.additionalQueryParams).subscribe(response => {
        this.records = response.data
        this.totalRecordsCount = response.total
        this.lastPage = response.last_page
        this.isLoading = false
      }, error => {
        this.toastService.showError('Die Suche ist fehlgeschlagen. Bitte entfernen Sie den zuletzt gesetzten Filter.')
      })

      if (this.persistentStateKey) {
        let persistentSettings: CrudGridPersistentInterface = {
          filterRegistry: this.filterRegistry,
          sortRegistry: this.sortRegistry,
          currentPageSize: this.currentPageSize,
          currentPage: this.currentPage
        }

        this.localStorageService.set(this.modelConfig!.serviceSlug + '_grid_' + this.persistentStateKey, persistentSettings)
      }
    }
  }

  formatHtmlText(s: string) {
    return s.replaceAll('&shy;', '')
  }

  viewRecordUpdate(replaceRecord: T) {
    this.records?.find((record, index) => {
      if (record.data[this.idProperty] == replaceRecord.data[this.idProperty]) {
        if (this.records) this.records[index] = replaceRecord
      }
    })
  }

  onMultiSelect() {
    if (! this.records?.length) return
    if (! this.modelConfig) return
    let ret: T[] = []
    for (const record of this.records) {
      if (this.selectionRegistry.includes(record.data[this.idProperty])) {
        ret.push(record)
      }
    }

    this.selectRecordsEvent.emit(ret)
  }
  toggleSelection(record: T) {
    if (! this.modelConfig) return
    if (this.selectionRegistry.includes(record.data[this.idProperty])) {
      let itemIndex = this.selectionRegistry.indexOf(record.data[this.idProperty]+'');
      this.selectionRegistry = this.selectionRegistry.filter((e, i) => i !== itemIndex);
    } else {
      this.selectionRegistry.push(record.data[this.idProperty])
    }
  }

  public onDuplicate(record: T) {
    let copyrecord = JSON.parse(JSON.stringify(record))
    copyrecord.data[this.idProperty] = undefined
    this.editRecordEvent.emit(copyrecord)
  }

  public onEdit(record: T) {
    if (this.multiSelectMode) {
      this.toggleSelection(record)
      return
    }

    if (this.modelConfig?.readonly) {
      this.viewRecordEvent.emit(record)
    } else {
      this.editRecordEvent.emit(record)
    }
  }

  public onView(record: T) {
    if (this.multiSelectMode) {
      this.toggleSelection(record)
      return
    }
    this.viewRecordEvent.emit(record)
  }

  public onDelete(record: T) {
    if (this.multiSelectMode) {
      this.toggleSelection(record)
      return
    }
    let that = this
    if (this.modelConfig?.readonly) return
    let modal = this.modalService.show(CrudGridDeleteComponent, {
      subscribers: {
        decisionEvent: function(decision: boolean) {
          if (decision) {
            that.deleteRecordEvent.emit(record)
          }
          modal.close()
        }
      }
    })
  }

  public onReplace(recordToReplace: T) {
    let that = this
    let modal = this.modalService.show(CrudGridReplaceComponent, {
      title: "Datensatz ersetzen",
      inputs: {
        recordToReplace: recordToReplace,
        serviceSlug: this.modelConfig?.serviceSlug
      },
      subscribers: {
        replaceWithEvent: function(recordToKeep: T) {
          that.api?.replace({ replace: recordToReplace.data, with: recordToKeep.data }).subscribe(r => {
            that.toastService.showSuccess('Der Datensatz wurde erfolgreich ersetzt!')
            that.load()
          }, error => {
            if (error.error.message) {
              that.toastService.showError(error.error.message)
            } else {
              that.toastService.showError('Datensatz konnte nicht ersetzt werden!')
            }
          })
          modal.close()
        }
      }
    })
  }

  public onHistoricize(recordToHistoricize: T) {
    let that = this
    let modal = this.modalService.show(CrudGridHistoricizeComponent, {
      title: "Datensatz historisieren",
      inputs: {
        recordToHistoricize: recordToHistoricize,
        serviceSlug: this.modelConfig?.serviceSlug
      },
      subscribers: {
        cancelEvent: function() {
          modal.close()
        },
        historicizeWithEvent: function(recordToHistoricizeWith: T) {
          that.api?.historicizePreview({ first: recordToHistoricize.data, second: recordToHistoricizeWith.data }).subscribe(response => {
            modal.close()
            let modal2 = that.modalService.show(CrudGridHistoricizePreviewComponent, {
              title: "Datensatz historisieren Vorschau",
              inputs: {
                historicizePreview: response,
                modelConfig: that.modelConfig,
                records: response
              },
              modalOptions: {
                windowClass: 'historicize-preview',
              },
              subscribers: {
                doHistoricizeEvent: function(answer: boolean) {
                  if (! answer) {
                    modal2.close()
                    that.toastService.showInfo('Das Historisieren wurde abgebrochen.')
                  } else {
                    that.api?.historicize({ first: recordToHistoricize.data, second: recordToHistoricizeWith.data }).subscribe(() => {
                      that.toastService.showSuccess('Die Datensätze wurden erfolgreich historisiert!')
                      modal2.close()
                      that.load()
                    }, error => {
                      if (error.error.message) {
                        that.toastService.showError(error.error.message)
                      } else {
                        that.toastService.showError('Die Datensätze konnten nicht historisiert werden!')
                      }
                    })
                  }
                }
              }
              })
          }, error => {
            that.toastService.showApiError(error, 'Die Vorschau zum Historisieren der Datensätze konnte nicht geladen werden!')
          })
        }
      }
    })
  }

  public onSelect(record: T) {
    if (this.multiSelectMode) {
      this.toggleSelection(record)
      return
    }
    this.selectRecordEvent.emit(record)
  }
  public newRecord() {
    if (this.modelConfig?.readonly) return
    const preselection: K = this.filterRegistry as K
    this.newRecordEvent.emit(preselection)
  }

  onQuicksearchKeyUp(event: KeyboardEvent, field: ModelConfigurationFieldInterface) {
    if (event.key == "Enter") {
      this.onQuicksearchHandleInput(field, event.target as HTMLInputElement)
    }
  }

  onQuicksearchFocusOut(event: FocusEvent, field: ModelConfigurationFieldInterface) {
    this.onQuicksearchHandleInput(field, event.target as HTMLInputElement)
  }
  onQuicksearchHandleInput(field: ModelConfigurationFieldInterface, input: HTMLInputElement) {
    if (input.value.length == 0) {
      this.onQuickSearch(field, undefined)
    } else {
      this.onQuickSearch(field, input.value)
    }
  }

  onSelectorSearch(field: ModelConfigurationFieldInterface, e: Event) {
    let value: string | number | undefined = (e.target as HTMLSelectElement).value
    if (value == "undefined") value = undefined
    this.onQuickSearch(field, value)
  }

  // onSpinTable(amount: number) {
  //   // this.tableWrap.nativeElement.scroll({left: amount})
  //   // if (amount < 0 && this.currentPage > 1) this.currentPage--
  //   // else if (amount > 0 && this.editionRecord && this.currentPage < this.editionRecord.data.pagecount) this.currentPage++
  // }

  onQuickSearch(field: ModelConfigurationFieldInterface, value?: string | number) {
    let prop = ''
    if (field.filterProperty) {
      prop = field.filterProperty
    } else if (field.type == "constraint") {
      prop = ModelConfigFactory.snakeToCamel(field.prop) + '.' + ModelConfigFactory.getNameFieldProperty(field.constraint?.table)
    } else {
      prop = field.prop
    }

    if (value === undefined) {
      if (this.filterRegistry[prop]) {
        delete this.filterRegistry[prop]
        this.currentPage = 1
        this.load()
      }
    } else {
      if (this.filterRegistry[prop] != value) {
        this.filterRegistry[prop] = value
        this.currentPage = 1
        this.load()
      }
    }
  }

  setPageSize(size: number) {
    this.currentPageSize = size
    this.currentPage = 1
    this.load()
  }

  setCurrentPage(page: number) {
    this.currentPage = page
    this.load()
  }

  public setSort(fieldname: string, direction: SortDirections) {
    let sortIdentifier = fieldname
    if (this.modelConfig?.serviceSlug) {
      const fieldConfig = ModelConfigFactory.getFieldConfig(this.modelConfig?.serviceSlug, fieldname)
      if (fieldConfig?.sortProperty) {
        sortIdentifier = fieldConfig?.sortProperty
      }
    }

    let found = this.sortRegistry.find((item) => { return (item.field == sortIdentifier) })
    if (found) {
      if (direction) {
        found.dir = direction
      } else {
        this.sortRegistry = this.sortRegistry.filter((item) => { return (item.field != sortIdentifier) })
      }
    } else {
      if (direction) {
        this.sortRegistry.push({field: sortIdentifier, dir: direction})
      }
    }

    this.load()
  }

  getSort(fieldname: string) {
    let found = this.sortRegistry.find((item) => { return (item.field == fieldname) })
    if (found) return found.dir
    return undefined
  }

  exportXls() {
    if (this.api) {
      this.isLoading = true
      this.api.export(this.getCurrentSearchCriterias()).subscribe(response => {
        this.isLoading = false
        if (response.success) {
          this.toastService.showSuccess(response.message)
        } else {
          this.toastService.showError(response.message)
        }
      }, error => {
        this.toastService.showError('Es ist ein Fehler beim Generieren des Exports aufgetreten!')
        this.isLoading = false
      })
    }
  }


  renderExportXlsListForDebug(mc: ModelConfigurationInterface): void {
    let headerString = "/**\n" +
      "     * @inheritDoc\n" +
      "     */\n" +
      "public function headings(): array\n" +
      "    {\n" +
      "        return [[\n"

    let mapString = "public function map($record): array\n" +
      "    {\n" +
      "        return [\n"

    for (let field of mc.fields) {
      headerString += "        '" + field.title + "',\n"
      mapString += '            $record->'
      if (field.type == "constraint") {
        mapString += "" + ModelConfigFactory.snakeToCamel(field.prop) + "->name,\n"
      } else {
        mapString += "" + field.prop + ",\n"
      }
    }

    headerString += "        ]];\n" +
      "    }\n"
    mapString += "];\n" +
      "    }\n"
    console.info("            protected $_recordsName = '" + mc.title + "';\n\n" + headerString + "\n\n" + mapString)
  }

  ngOnInit(): void {
    if (! this.modelConfig) return

    // define backend properties
    // TODO: put this into dev
    // this.renderExportXlsList(this.modelConfig)

    this.authService.getUserSubject().subscribe(user => {
      if (! user) {
        this.userIsAllowedToExport = false
        return
      }

      if (this.authService.hasAbility("spa-grid-export")) {
        this.userIsAllowedToExport = true
      } else {
        this.userIsAllowedToExport = false
      }
    })

    if (! this.actions) {

      let actions: GridActionsInterface[] = [
        {
          label: "bearbeiten",
          method: this.onEdit,
          icon: "pencil",
          identifier: 'edit',
          scope: this,
          requiredAbility: "spa-user"
        },
        {
          label: "löschen",
          method: this.onDelete,
          icon: "x-circle",
          identifier: 'delete',
          scope: this,
          requiredAbility: "spa-user"
        },
        {
          label: "duplizieren",
          method: this.onDuplicate,
          icon: "files",
          identifier: 'duplicate',
          scope: this,
          requiredAbility: "spa-user"
        },
      ]

      if (this.modelConfig.allowReplace) {
        actions.push({
          label: "ersetzen",
          method: this.onReplace,
          icon: "repeat",
          identifier: 'replace',
          scope: this,
          requiredAbility: "spa-user"
        })
      }

      if (this.modelConfig.allowHistoricize) {
        actions.push({
          label: "historisieren",
          method: this.onHistoricize,
          icon: "calendar2-date",
          identifier: 'replace',
          scope: this,
          requiredAbility: "spa-poweruser"
        })
      }

      this.actions = []
      for (let action of actions) {
        if (action.hasOwnProperty('requiredAbility')) {
          if (! this.authService.hasAbility(action.requiredAbility!)) {
            continue
          }
        }
        this.actions.push(action)
      }

      if (this.additionalActions) {
        for (const addAction of this.additionalActions) {
          for (let action of this.actions) {
            if (addAction.identifier == action.identifier) {
              // remove existing
              this.actions = this.actions.filter(a => {
                return a.identifier != addAction.identifier
              })
            }
          }

          if (addAction.hasOwnProperty('requiredAbility')) {
            if (this.authService.hasAbility(addAction.requiredAbility!)) {
              this.actions.push(addAction)
            }
          } else {
            this.actions.push(addAction)
          }
        }
      }
    }
    if (this.fixedFilters) {
      this.filterRegistry = JSON.parse(JSON.stringify(this.fixedFilters))
    }

    if (this.presetFilters) {
      this.filterRegistry = { ...this.filterRegistry, ...this.presetFilters }
    }

    if (this.modelConfig.defaultSort) this.sortRegistry = this.modelConfig.defaultSort

    this.idProperty = ModelConfigFactory.getIdFieldProperty(this.modelConfig.serviceSlug)

    if (this.gridReloadSubject) {
      this.gridReloadSubject.subscribe(reload => {
        if (reload) this.load()
      })
    }

    if (this.persistentStateKey) {
      const persistenceInfo: CrudGridPersistentInterface | undefined = this.localStorageService.get(this.modelConfig.serviceSlug + '_grid_' + this.persistentStateKey) as CrudGridPersistentInterface
      if (persistenceInfo) {
        this.currentPage = persistenceInfo.currentPage
        this.currentPageSize = persistenceInfo.currentPageSize
        this.sortRegistry = persistenceInfo.sortRegistry
        this.filterRegistry = persistenceInfo.filterRegistry
      }
    }

    this.load()
  }

  getConstraintFilterValue(field: ModelConfigurationFieldInterface) {
    let val: any = ''
    try {
      val = this.filterRegistry[ModelConfigFactory.snakeToCamel(field.prop) + '.' + ModelConfigFactory.getNameFieldProperty(field.constraint!.table)]
    } catch (e) {}
    return val ?? ''
  }

  getVirtualFilterValue(field: ModelConfigurationFieldInterface) {
    if (! field.filterProperty) {
      return '<no filterProperty defined!>'
    }
    let val: string = ''
    try {
      val = this.filterRegistry[field.filterProperty] ? this.filterRegistry[field.filterProperty] + '' : ''
    } catch (e) {}
    return val ?? ''
  }

  resetCurrentAndPersistent() {
    if (this.persistentStateKey) {
      this.localStorageService.remove(this.modelConfig!.serviceSlug + '_grid_' + this.persistentStateKey)
    }
    this.currentPage = 1
    this.currentPageSize = 20
    this.sortRegistry = []
    this.filterRegistry = {}
    this.load()
  }
}
