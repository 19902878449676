import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import {
  FiltersInterface,
  KeyValueInterface,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface
} from "../../../models/main";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { HttpAbstractRecordService } from "../../../services/http/record.service.abstract";
import { HttpServiceFactory } from "../../../services/http/factory";
import { ModalService } from "../../../services/modal.service";
import { CrudGridComponent } from "../../../crud-grid/crud-grid.component";
import { ModelConfigFactory } from "../../../models/factory";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'wefra-multi-form-field',
  templateUrl: './multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiComponent
    }
  ]
})
export class MultiComponent<T extends RecordInterface<RecordDataInterface>, K extends RecordDataInterface> implements OnInit, ControlValueAccessor {
  @Input({ required: false }) public record?: T
  @Input({ required: true }) public fieldConfig?: ModelConfigurationFieldInterface
  @Input() fixedFilters?: FiltersInterface
  @Input() renderFields?: string[]
  @Input() gridLimitToFields?: string[]
  @Input() additionalQueryParams?: KeyValueInterface
  @Input() gridPageSize?: number
  @Input({ required: false }) public readonly: boolean = false
  @Input() foreignRecord?: RecordInterface<RecordDataInterface>

  @Output("selectRecord") selectRecordEvent = new EventEmitter<RecordInterface<RecordDataInterface> | undefined>()
  // @Output("selectRecords") selectRecordsEvent = new EventEmitter<RecordInterface<RecordDataInterface>[]>()

  @Input() selectedRecordTemplate?: TemplateRef<any>

  modelConfig?: ModelConfigurationInterface

  disabled: boolean = false
  value?: number

  recordService?: HttpAbstractRecordService<T, K>
  open: boolean = false
  touched: boolean = false

  modal?: NgbModalRef

  constructor(
    @Inject(HttpServiceFactory) private serviceFactory: HttpServiceFactory,
    @Inject(ModalService) private modalService: ModalService
  ) {
  }

  closeGridModal() {
    this.modal?.close()
    this.modal = undefined
  }

  openGridModalIfNoRecord() {
    if (this.readonly) return
    if (! this.foreignRecord) this.openGridModal()
  }

  openGridModal() {
    if (this.readonly) return
    if (this.modal) return
    if (! this.fieldConfig?.constraint?.table) return
    let that = this
    this.modal = this.modalService.show(CrudGridComponent, {
      inputs: {
        modelConfig: this.modelConfig,
        api: this.recordService,
        selectOnly: true,
        fixedFilters: this.fixedFilters,
        presetFilters: this.modelConfig?.defaultFilter ? this.modelConfig?.defaultFilter : undefined,
        allowMultiSelect: false,
        limitToFields: this.gridLimitToFields,
        additionalQueryParams: this.additionalQueryParams,
        currentPageSize: this.gridPageSize
      },
      closeBtnText: undefined,
      title: this.fieldConfig?.title,
      modalOptions: {
        windowClass: 'grid-modal',
      },
      subscribers: {
        selectRecordEvent: function(record: RecordInterface<RecordDataInterface>) {
          that.foreignRecord = record
          that.closeGridModal()
          that.selectRecordEvent.emit(record)
          that.onRecordSelect(record as T)
        },
      }
    })
    this.modal.closed.subscribe(() => {
      this.modal = undefined
    })
    this.modal.dismissed.subscribe(() => {
      this.modal = undefined
    })
  }

  onChange = (value: any) => {}

  onTouched = () => {}

  ngOnInit(): void {
    if (! this.fieldConfig) return
    // @ts-ignore
    this.recordService = this.serviceFactory.getTypeInstance(this.fieldConfig.constraint?.table)
    if (this.fieldConfig?.constraint?.table) {
      this.modelConfig = ModelConfigFactory.getConfig(this.fieldConfig?.constraint?.table)
    }
  }

  onRecordSelect(record: T) {
    if (this.readonly) return
    const idProperty = ModelConfigFactory.getIdFieldProperty(this.fieldConfig?.constraint?.table)
    if (this.record) {
      this.record.resolved[this.fieldConfig!.prop] = record
      this.record.data[this.fieldConfig!.prop] = record.data[idProperty]
    }
    this.value = record.data[idProperty]
    this.onChange(record.data[idProperty])
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled
  }

  writeValue(id: number): void {
    this.value = id
  }

  markAsTouched() {
    if (! this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  clearField(e?: MouseEvent) {
    this.foreignRecord = undefined
    this.value = undefined
    if (this.record) {
      delete this.record.resolved[this.fieldConfig!.prop]
      this.record.data[this.fieldConfig!.prop] = undefined
    }
    this.selectRecordEvent.emit(undefined)
  }
}
