import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { AuthService } from "../../auth.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  authService: AuthService
  constructor(
    @Inject(AuthService) authService: AuthService
  ) {
    this.authService = authService
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      event => event instanceof HttpResponse ? 'succeeded' : '',
      error => {
        if (error.status == 401) {
          this.authService.removeUser()
          location.href = "/"
        }
      }
    ))
  }
}
