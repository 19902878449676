import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'wefra-switch-form-field',
  templateUrl: './switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SwitchComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent implements OnInit, ControlValueAccessor {

  @Input() disabled: boolean = false
  @Input() readonly: boolean = false
  @Input() size: string = "2em"

  @Output("valueChange") valueChangeEvent = new EventEmitter<number>()


  value: number = 0
  open: boolean = false
  touched: boolean = false

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }
  onChange = (value: number) => {};

  toggleValue() {
    this.value = (this.value == 0) ? 1 : 0
    this.onChange(this.value)
    this.valueChangeEvent.emit(this.value)
  }

  onTouched = () => {}

  ngOnInit(): void {}

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled
    this.cd.detectChanges()
  }

  writeValue(id: number): void {
    this.value = id
    this.cd.detectChanges()
  }

  markAsTouched() {
    if (! this.touched) {
      this.onTouched()
      this.touched = true
      this.cd.detectChanges()
    }
  }
}
