import { Injectable } from "@angular/core";
import { HttpBaseService } from "./base.service";
import { ExportResponseInterface } from "../../models/main";
import { Observable } from "rxjs";

@Injectable()
export class HttpExportService extends HttpBaseService {
  export() {
    let url: URL = new URL(this.getMainApiBaseUrl() + 'export')
    return this.http.get<ExportResponseInterface>(url+'')
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}
