import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { AuthRequestInterface, AuthResponseInterface, LogoutResponseInterface } from "../models/auth";
import { HttpAuthService } from "./http/auth.service";
import { LocalStorageService } from "./localstorage.service";
import { ModalService } from "./modal.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  modalService: ModalService
  httpAuthService: HttpAuthService
  storage: LocalStorageService
  userResponse?: AuthResponseInterface
  userSubject: BehaviorSubject<AuthResponseInterface | undefined>
  refreshIntervalSubscription?: Subscription
  refreshInterval: Observable<number>
  constructor(
    @Inject(ModalService) modalService: ModalService,
    @Inject(HttpAuthService) httpAuthService: HttpAuthService,
    @Inject(LocalStorageService) storage: LocalStorageService,
  ) {
    this.refreshInterval = interval(300000)

    this.modalService = modalService
    this.httpAuthService = httpAuthService
    this.storage = storage
    this.userSubject = new BehaviorSubject<AuthResponseInterface | undefined>(this.storage.get('userResponse') as AuthResponseInterface)
    this.userSubject.subscribe(value => {
      if (! value) {
        this.refreshIntervalSubscription?.unsubscribe()
        this.storage.remove('userResponse')
        this.userResponse = undefined
      } else {
        this.subscribeToRefreshInterval()
        this.storage.set('userResponse', value)
        this.userResponse = value
      }
    })
  }

  getToken() {
    return this.userResponse?.authorization?.token
  }

  getUserSubject() {
    return this.userSubject
  }

  logout() {
    let retobs = new Observable<LogoutResponseInterface>((observer) => {
      this.httpAuthService.logout().subscribe(value => {
        this.userSubject.next(undefined)
        observer.next(value)
      }, error => {
        this.userSubject.next(undefined)
        observer.next(undefined)
      })
    })
    return retobs
  }

  login(credentials: AuthRequestInterface): Observable<AuthResponseInterface> {
    let retobs = new Observable<AuthResponseInterface>((observer) => {
      this.httpAuthService.login(credentials).subscribe(value => {
        console.info("Login successful:" , value)
        this.userSubject.next(value)
        observer.next(value)
      }, error => {
        console.info("Login failed!")
        this.userSubject.error('login failed')
        observer.next(undefined)
      })
    })

    return retobs
  }

  private subscribeToRefreshInterval() {
    this.refreshIntervalSubscription?.unsubscribe()
    this.refreshIntervalSubscription = this.refreshInterval.subscribe(refreshId => {
      this.refresh()
    })
  }

  public refresh() {
    this.httpAuthService.refresh().subscribe(response => {
      this.userSubject.next(response)
      console.info('Token refresh...')
    })
  }

  public removeUser() {
    this.userSubject.next(undefined)
  }
  
  public hasAbility(a: string): boolean {
    if (! this.userResponse || ! this.userResponse.user.data.abilities) return false
    if (this.userResponse.user.data.abilities.includes('*')) return true
    return this.userResponse.user.data.abilities.includes(a)
  }
}
