import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktPlacementRecordDataInterface extends RecordDataInterface {
  placement_key: number
  placement_name: string
  placement_name_short: string
  sort: number
  active: number
  de: number
  at: number
  ch: number
}

export interface WasmuthFktPlacementRecordInterface extends RecordInterface<WasmuthFktPlacementRecordDataInterface> {
}

export const WasmuthFktPlacementModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth FKT Placements",
  titleSingular: "Wasmuth FKT Placements",
  serviceSlug: "wasmuth_fkt_placements",
  fields: [
    { type: 'id', title: '#', prop: 'placement_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'placement_name', isNameProperty: true },
    { type: 'int', title: "Sortierung", prop: "sort"},
    { prop: 'placement_name_short', type: "text", title: 'Name kurz' },
    { prop: 'active', type: "bool", title: '' },
    { prop: 'de', type: "bool", title: '' },
    { prop: 'at', type: "bool", title: '' },
    { prop: 'ch', type: "bool", title: '' },
  ]
}
