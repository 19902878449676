import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktAppearingWeeklyRecordDataInterface extends RecordDataInterface {
  appearing_weekly_key: number
  appearing_name: string
  appearing_plis: number
  additional_days: number
  position_xzet: number
  count_xzet: number
  appearing_xzet: number
}

export interface WasmuthFktAppearingWeeklyRecordInterface extends RecordInterface<WasmuthFktAppearingWeeklyRecordDataInterface> {
}

export const WasmuthFktAppearingWeeklyModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Erscheint wöchentlich",
  titleSingular: "Erscheint wöchentlich",
  serviceSlug: "wasmuth_fkt_appearing_weekly",
  fields: [
    { type: 'id', title: '#', prop: 'appearing_weekly_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'appearing_name', isNameProperty: true },
    { prop: 'appearing_plis', type: "int", title: 'appearing_plis' },
    { prop: 'additional_days', type: "int", title: 'additional_days' },
    { prop: 'position_xzet', type: "int", title: 'position_xzet' },
    { prop: 'count_xzet', type: "int", title: 'count_xzet' },
    { prop: 'appearing_xzet', type: "int", title: 'appearing_xzet' },
  ]
}






