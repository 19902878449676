import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthMediaRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  company_id: number
  market_id: number
  submarket_id: number
  atc_code: string
  atc_description: string
  pharmacy_only: boolean
  prescription_only: boolean
  drugs: boolean
  product_new: string
  product_category_id: number
}

export interface WasmuthMediaRecordInterface extends RecordInterface<WasmuthMediaRecordDataInterface> {
}

export const WasmuthMediaModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Medien",
  titleSingular: "Wasmuth Medien",
  serviceSlug: "wasmuth_media",
  fields: [
    { type: 'id', title: '#', prop: 'medium_id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name 1', prop: 'name1', isNameProperty: true },
    { type: "text", title: 'Name 2', prop: 'name2' },
    { type: "text", title: 'Beschreibung', prop: 'description' },
    { type: "text", title: 'Regional', prop: 'regional' },
    { type: "text", title: 'OID', prop: 'oid' },
    { type: "text", title: 'Edition', prop: 'edition' },
    { type: "bool", title: 'Buchbar', prop: 'bookable' },
    { type: "bool", title: 'Aktiv', prop: 'active' },
    { type: "datetime", title: 'Gültig seit', prop: 'valid_since' },
    { type: "datetime", title: 'Gültig bis', prop: 'valid_till' },
    { type: "datetime", title: 'Letzte Änderung', prop: 'modifydate' },
  ]
}

