import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface ApilogRecordDataInterface extends RecordDataInterface {
  id: number
  created_at: string
  updated_at: string
  method: string
  uri: string
  files: string
  body: string
  headers: string
  user: number
}

export interface ApilogRecordInterface extends RecordInterface<ApilogRecordDataInterface> {

}

export const ApilogModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "apilogs",
  allowExportXlsx: false,
  title: "Apilogs",
  titleSingular: "Apilog",
  readonly: true,
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'uri', type: "text", title: 'Pfad', isNameProperty: true },
    { prop: 'user', type: "constraint", title: 'Benutzer', constraint: { table: 'users' }},
    { prop: 'method', type: "text", title: 'Methode' },
    { prop: 'body', type: "json", title: 'Payload' },
    { prop: 'headers', type: "json", title: 'Headers', list: false },
    { prop: 'files', type: "text", title: 'Dateien', list: false },
    { prop: "created_at", ability: "spa-poweruser", type: "text", title: 'Zeitpunkt', list: true },
  ]
}
