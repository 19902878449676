import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthPublisherRecordDataInterface extends RecordDataInterface {
  publisher_id: number
  publisher_name: string
  publisher_initial: string
  description: string
  country_code: string
  crid: string
  uid: string
  place: string
}

export interface WasmuthPublisherRecordInterface extends RecordInterface<WasmuthPublisherRecordDataInterface> {
}

export const WasmuthPublisherModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Publisher",
  titleSingular: "Wasmuth Publisher",
  serviceSlug: "wasmuth_publishers",
  fields: [
    { type: 'id', title: '#', prop: 'publisher_id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'publisher_name', isNameProperty: true },
    { type: "text", title: 'Beschreibung', prop: 'description' },
    { type: "text", title: 'Ländercode', prop: 'country_code' },
    { type: "text", title: 'Initialer Name', prop: 'publisher_initial' },
    { type: "text", title: 'CRID', prop: 'crid' },
    { type: "text", title: 'UID', prop: 'uid' },
    { type: "text", title: 'Place', prop: 'place' },
  ]
}

