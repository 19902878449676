import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktPageFormatCodeRecordDataInterface,
  WasmuthFktPageFormatCodeRecordInterface
} from "../../models/wasmuth_fkt_page_format_code";

@Injectable()
export class HttpWasmuthFktPageFormatCodeService extends HttpAbstractRecordService<WasmuthFktPageFormatCodeRecordInterface, WasmuthFktPageFormatCodeRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_page_format_codes'
}
