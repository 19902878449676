<nav class="navbar">
  <ul class="navbar-nav">
    <li class="nav-item">
      <span class="nav-title">Werbeträger</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/ad-spendings/editions']">Printmedien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/ad-spendings/list']">Anzeigen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/monitor']">Monitor</a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="userIsSpaUser">
      <span class="nav-title">Wasmuth Tabellen</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wasmuth-tables/media']">Werbeträger</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wasmuth-tables/media-ratecards']">Media Ratecards</a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="userIsPoweruser">
      <span class="nav-title">WEFRA Tabellen</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/editions']">Printmedien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adspendings']">Anzeigen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/magazines']">Magazine</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/publishers']">Verlage</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/target_audiences']">Zielgruppen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/companies']">Firmen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/company_categories']">Firmenkategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/markets']">Branchen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/submarkets']">Unterbranchen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/products']">Produkte</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/product_categories']">Produktkategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/motives']">Motive</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/categories']">Kategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/colors']">Farben</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/formats']">Formate</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/prices']">Preise</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/advertising_forms']">Werbeformen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wefra_ratecards']">Wefra Ratecards</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wefra_pubdates']">Wefra Erscheinungsdaten</a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="userIsAdmin">
      <span class="nav-title" [routerLink]="['/admin']">Admin</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/admin/logs']">Benutzeraktivität</a>
        </li>
      </ul>
    </li>
  </ul>
</nav>

