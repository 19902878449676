import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecordDataInterface, RecordInterface, ServiceSlugProperty } from "../models/main";

@Component({
  selector: 'wefra-crud-grid-replace',
  templateUrl: './replace.component.html',
})
export class CrudGridReplaceComponent<T extends RecordInterface<K>, K extends RecordDataInterface> {
  @Output("replaceWith") replaceWithEvent = new EventEmitter<T>()

  @Input() recordToReplace?: RecordInterface<RecordDataInterface>
  @Input() serviceSlug: ServiceSlugProperty = "not_defined"
  @Input() valueProperty: string = 'name'

  recordToKeep?: RecordInterface<RecordDataInterface>
  invalid = false

  onSubmit() {
    if (! this.recordToKeep) {
      this.invalid = true
      return
    }
    this.replaceWithEvent.emit(this.recordToKeep as T)
  }

  onSelectRecordToKeep(recordToKeep?: RecordInterface<RecordDataInterface>) {
    this.recordToKeep = recordToKeep
    this.invalid = ! recordToKeep
  }
}
