import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'wefra-admin-component',
  templateUrl: './admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminComponent {

  constructor(
  ) {
  }
}
