import { Component, OnInit } from '@angular/core';
import { RecordDataInterface, RecordInterface } from "../models/main";
import { BasicGridComponent } from "../basic-grid/baslc-grid.component";

@Component({
  selector: 'wefra-mini-grid',
  templateUrl: './mini-grid.component.html',
  styleUrls: []
})
export class MiniGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface> extends BasicGridComponent<T, K> implements OnInit {

  ngOnInit(): void {
  }

  onSelect(record: T) {
    this.selectRecordEvent.emit(record)
  }

}
