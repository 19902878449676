import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  AdSpendingModelConfiguration,
  AdSpendingRecordDataInterface,
  AdSpendingRecordInterface
} from "../models/ad_spending";
import { HttpAdSpendingsService } from "../services/http/ad_spendings.service";

@Component({
  selector: 'wefra-generic-adspendings',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class GenericAdSpendingsComponent extends AbstractRecordComponent<AdSpendingRecordInterface, AdSpendingRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = AdSpendingModelConfiguration
  api = inject(HttpAdSpendingsService)

  constructor(
  ) {
    super()
  }
}
