import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModelConfigurationInterface, RecordInterface } from "../../models/main";

@Component({
  selector: 'wefra-crud-grid-controls',
  templateUrl: './controls.component.html',
})
export class ControlsComponent {
  @Input() public modelConfig?: ModelConfigurationInterface
  @Output("newRecord") newRecordEvent = new EventEmitter<boolean>()

  newRecord() {
    this.newRecordEvent.emit(true)
  }
}
