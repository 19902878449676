import { ModelConfigurationFieldInterface, ModelConfigurationInterface, ServiceSlugProperty } from "./main";

export interface ModelconfigKeyIndex {
  [identifier: string]: ModelConfigurationInterface
}

export class ModelConfigFactory {

  public static configs: ModelconfigKeyIndex = {}

  public static register(key: ServiceSlugProperty, config: ModelConfigurationInterface){
    ModelConfigFactory.configs[key] = config
  }

  public static getConfig(key: ServiceSlugProperty): ModelConfigurationInterface {
    if (key == 'not_defined') {
      throw new Error("The service-slug has not been defined in your component!")
    }
    if (! ModelConfigFactory.configs[key] || ModelConfigFactory.configs[key] === undefined) {
      throw new Error("Modelconfig by key " + key + " not found")
    }
    // @ts-ignore
    return ModelConfigFactory.configs[key]
  }

  public static getFieldConfig(model: ServiceSlugProperty, prop: string): ModelConfigurationFieldInterface | undefined {
    const mc = this.getConfig(model)

    if (! mc) return undefined
    for (const field of mc.fields) {
      if (field.prop == prop) return field
    }

    return undefined
  }

  public static getIdFieldProperty(model?: ServiceSlugProperty): string {
    if (! model) return "id"
    const mc = this.getConfig(model)
    if (! mc) return "id"
    for (const field of mc.fields) {
      if (field.isIdProperty) return field.prop
    }

    return "id"
  }

  public static getNameFieldProperty(model?: ServiceSlugProperty): string {
    if (! model) return "name"
    const mc = this.getConfig(model)
    if (! mc) return "name"
    for (const field of mc.fields) {
      if (field.isNameProperty) return field.prop
    }

    return "name"
  }

  public static snakeToCamel(str: string) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    )
  }
}
