import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { ToastService } from "../services/toast.service";
import { HttpExportService } from "../services/http/export.service";
import { StateService } from "../services/state.service";
import { AdSpendingRecordInterface } from "../models/ad_spending";

export interface BreadcrumbPath {
  label: string
  link: string
}

@Component({
  selector: 'wefra-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Input() authenticated: boolean = false

  jsonExportLoading: boolean = false
  exportReady: boolean = false
  downloadLink?: string
  currentCaptureRecord?: AdSpendingRecordInterface
  userIsAllowedToExport: boolean = false

  constructor(
    @Inject(AuthService) public authService: AuthService,
    @Inject(ToastService) public toastService: ToastService,
    @Inject(HttpExportService) private exportService: HttpExportService,
    @Inject(StateService) private stateService: StateService
  ) {
  }
  refresh() {
    this.authService.refresh()
  }
  public logout() {
    this.authService.logout().subscribe(response => {
      this.toastService.showSuccess('Sie haben sich erfolgreich abgemeldet!')
    })
  }

  public jsonExport() {
    this.exportReady = false
    this.jsonExportLoading = true
    this.exportService.export().subscribe(result => {
      if (result.success) {
        this.exportReady = true
        this.downloadLink = result.link
        this.toastService.showSuccess('Der Export wurde erfolgreich vorbereitet!')
      } else {
        this.toastService.showError('Es ist ein Fehler aufgetreten: ' + result.message)
      }
      this.jsonExportLoading = false
    }, error => {
      this.jsonExportLoading = false
      this.toastService.showError('Es ist ein Fehler aufgetreten: ' + error)
    })
  }

  ngOnInit(): void {
    this.stateService.getCurrentCaptureSubject().subscribe(adSpending => {
      this.currentCaptureRecord = adSpending
    })
    this.authService.getUserSubject().subscribe(user => {
      if (! user) {
        this.userIsAllowedToExport = false
        return
      }

      if (this.authService.hasAbility("spa-export")) {
        this.userIsAllowedToExport = true
      } else {
        this.userIsAllowedToExport = false
      }
    })
  }
}
