<ng-container>
  <div class="row">
    <div class="col-12 pe-5">
      <div *ngIf="response" class="float-right total">{{ response.hits.total.value }} Datensätze</div>
    </div>
  </div>
  <div class="table-wrap">
    <table class="table">
      <thead>
        <tr>
            <ng-container *ngFor="let field of fields">
              <th scope="col" [class]="field.prop">
<!--                <div class="title-icon-wrap">-->
<!--                  <ng-container>-->
<!--                    <i-bs *ngIf="getSort(field.prop) == 'desc'" name="arrow-up" class="active" (click)="setSort(field.prop, undefined)" ngbTooltip="Absteigend sortiert"></i-bs>-->
<!--                    <i-bs *ngIf="getSort(field.prop) == 'asc'" (click)="setSort(field.prop, 'desc')" class="active" name="arrow-down" ngbTooltip="Aufsteigend sortiert"></i-bs>-->
<!--                    <i-bs *ngIf="getSort(field.prop) == undefined" (click)="setSort(field.prop, 'asc')" name="arrow-down-up" ngbTooltip="Nicht sortiert"></i-bs>-->
<!--                  </ng-container>-->
                  {{ field.title }}
<!--                </div>-->
                <ng-container *ngIf="field.type == 'bool'">
                  <select (change)="onSelectorSearch(field, $event)">
                    <option [value]="undefined"></option>
                    <option [value]="1">Ja</option>
                    <option [value]="0">Nein</option>
                  </select>
                </ng-container>
                <ng-container *ngIf="field.type == 'enum'">
                  <select (change)="onSelectorSearch(field, $event)">
                    <option [value]="undefined"></option>
                    <option *ngFor="let pair of field.enums" [value]="pair.value">{{ pair.label }}</option>
                  </select>

                </ng-container>
                <input *ngIf="! ['enum', 'bool'].includes(field.type) "
                  type="text"
                  [value]="swordRegistry[field.prop] ?? ''"
                  (focusout)="this.onQuicksearchFocusOut($event, field)"
                  (keyup)="this.onQuicksearchKeyUp($event, field)"
                />
              </th>
            </ng-container>
        </tr>
      </thead>
      <tbody>

      <ng-container *ngIf="! response; else loaded">
        <tr>
          <td [colSpan]="fields.length">
            <div class="d-flex justify-content-center h-100">
              <div class="spinner-border" role="status">
              </div>
            </div>
          </td>
        </tr>
      </ng-container>

      <ng-template #loaded>
        <ng-container *ngFor="let record of response?.hits?.hits; let i = index">
        <tr [class]="'row-' + i" (click)="onClick(record._source, i)">
          <ng-container *ngFor="let field of fields">
            <td class="{{ field.prop }}">
              {{ record._source[field.prop] }}
            </td>
          </ng-container>
        </tr>
        </ng-container>
      </ng-template>
      </tbody>
    </table>
  </div>
</ng-container>
