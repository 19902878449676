import { Component, inject } from '@angular/core';
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { ModelConfigurationInterface } from "../models/main";
import {
  AdSpendingModelConfiguration,
  AdSpendingRecordDataInterface,
  AdSpendingRecordInterface
} from "../models/ad_spending";
import { HttpAdSpendingsService } from "../services/http/ad_spendings.service";
import { Router } from "@angular/router";

@Component({
  selector: 'wefra-ad-spendings',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class AdSpendingsComponent extends AbstractRecordComponent<AdSpendingRecordInterface, AdSpendingRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = AdSpendingModelConfiguration
  api = inject(HttpAdSpendingsService)
  router: Router

  override gridPersistentStateKey = "customized"

  constructor(
  ) {
    super()
    this.additionalGridActions = [
      {label: "bearbeiten", method: this.onEditAdSpendingsRecord, icon: "pencil", identifier: 'edit', scope: this, requiredAbility: 'spa-user'},
      {label: "duplizieren", method: this.onDuplicateAdSpendingsRecord, icon: "files", identifier: 'duplicate', scope: this, requiredAbility: 'spa-user'},
    ]
    this.router = inject(Router)
  }

  onEditAdSpendingsRecord(record: AdSpendingRecordInterface) {
    // new record format with wasmuth data
    if (record.data.edition_id) {
      this.router.navigate(
        [ 'ad-spendings/capture/' + record.data.edition_id ],
        {queryParams: {adspendingId: record.data.id}})
    } else { // old records without wasmuth data
      this.onEditRecord(record)
    }
  }

  onDuplicateAdSpendingsRecord(record: AdSpendingRecordInterface) {
    let myRecord = JSON.parse(JSON.stringify(record))
    // new record format with wasmuth data
    if (myRecord.data.edition_id) {
      this.router.navigate(
        [ 'ad-spendings/capture/' + myRecord.data.edition_id ],
        {queryParams: {adspendingId: myRecord.data.id, copy: true}})
    } else { // old records without wasmuth data
      delete myRecord.data.id
      this.onEditRecord(myRecord)
    }
  }
}
