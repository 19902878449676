import { Component, inject, Inject, OnInit } from '@angular/core';
import { ModelConfigurationInterface, RecordInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { SubmarketRecordDataInterface, SubmarketRecordInterface, SubmarketModelConfiguration } from "../models/submarket";
import { HttpSubmarketService } from "../services/http/submarket.service";

@Component({
  selector: 'wefra-submarkets',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class SubmarketsComponent extends AbstractRecordComponent<SubmarketRecordInterface, SubmarketRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = SubmarketModelConfiguration
  api = inject(HttpSubmarketService)

  constructor(
  ) {
    super()
  }
}
