<div class="load-mask" *ngIf="records == undefined; else loaded">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
      </div>
    </div>
</div>
<ng-template #loaded>
  <table class="table table-hover" [class]="modelConfig?.serviceSlug">
    <thead>
    <tr>
      <ng-container *ngFor="let field of getFields()">
        <th scope="col" [class]="field.prop" [class.name-property]="field.isNameProperty" [class.id-property]="field.isIdProperty" >
            {{ field.title }}
        </th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="records?.length; else emptylist">
        <tr *ngFor="let record of records" (click)="onSelect(record)">
          <ng-container *ngFor="let field of getFields()">
            <td class="{{ field.prop }} pointer">
              <wefra-crud-grid-field-renderer [context]="'mini-grid'" [record]="record" [fieldConfig]="field"></wefra-crud-grid-field-renderer>
            </td>
          </ng-container>
        </tr>
      </ng-container>
      <ng-template #emptylist>
        <tr class="empty"><td [attr.colspan]="getFields().length">Es konnten keine {{ modelConfig?.title }} gefunden werden!</td></tr>
      </ng-template>
    </tbody>
  </table>
</ng-template>
