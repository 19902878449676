import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktPlacementRecordDataInterface,
  WasmuthFktPlacementRecordInterface
} from "../../models/wasmuth_fkt_placement";

@Injectable()
export class HttpWasmuthFktPlacementService extends HttpAbstractRecordService<WasmuthFktPlacementRecordInterface, WasmuthFktPlacementRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_placements'
}
