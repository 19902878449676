import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface PriceRecordDataInterface extends RecordDataInterface {
  id?: number
  magazine_id: number
  applicable_from: string
  category_id: number
  format_id: number
  price4c: number
  price3c: number
  price2c: number
  priceSW: number
  surcharge4c: number
  surcharge3c: number
  surcharge2c: number
  priceDS: number
  wasmuth_id: number
  wefra_ratecard_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface PriceRecordInterface extends RecordInterface<PriceRecordDataInterface> {
}

export const PriceModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "prices",
  allowExportXlsx: true,
  title: "Preise",
  titleSingular: "Preis",
  defaultFilter: { applicable_from: new Date().getFullYear() - 1 },
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "constraint", title: 'Magazin', prop: 'magazine_id', constraint: { table: 'magazines' }, validators: [ Validators.required ]},
    { type: "datestring", title: 'Gültig ab', prop: 'applicable_from', validators: []},
    { type: "constraint", title: 'Kategorie', prop: 'category_id', constraint: { table: 'categories' }, validators: [ Validators.required ]},
    { type: "constraint", title: 'Format', prop: 'format_id', constraint: { table: 'formats' }, validators: [ Validators.required ]},
    { type: "int", title: 'Preis 4c', prop: 'price4c', validators: [ Validators.required ]},
    { type: "int", title: 'Preis 3c', prop: 'price3c', validators: []},
    { type: "int", title: 'Preis 2c', prop: 'price2c', validators: []},
    { type: "int", title: 'Preis SW', prop: 'priceSW', validators: []},
    { type: "int", title: 'Aufschlag 4c', prop: 'surcharge4c', validators: []},
    { type: "int", title: 'Aufschlag 3c', prop: 'surcharge3c', validators: []},
    { type: "int", title: 'Aufschlag 2c', prop: 'surcharge2c', validators: []},
    { type: "int", title: 'Preis DS', prop: 'priceDS', validators: []},
    { type: "int", title: 'Wasmuth ID', prop: 'wasmuth_id', validators: []},
    { type: "constraint", title: 'Wefra Ratecard', prop: 'wefra_ratecard_id', constraint: { table: 'wefra_ratecards' }},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "datestring", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "datestring", title: 'Bearbeitet am', edit: false},
  ]
}
