import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthAdspecialRecordDataInterface extends RecordDataInterface {
  medium_id: string
  ratecard_id: string
  ratecard_number: string
  adspecial_type_key: number
  minimum_height: number
  maximum_height: number
  minimum_width: number
  maximum_width: number
  maximum_weight: number
  appearing_weekly_key: number
  payment_bit: number
  by_request: number
  splitt: number
  splitt_area_bit: number
  edition_minimum: number
  oid: string
  modifydate: string
  description: string
  sort: number
  page_format_numeric_value: number
  page_format_colour_key: number
  specimen_copy_count: number
  processing_addition: number
}

export interface WasmuthAdspecialRecordInterface extends RecordInterface<WasmuthAdspecialRecordDataInterface> {
}

export const WasmuthAdspecialModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Adspecials",
  titleSingular: "Wasmuth Adspecials",
  serviceSlug: "wasmuth_adspecials",
  fields: [
    { type: 'id', title: '#', prop: 'oid', isIdProperty: true },
    { prop: 'medium_id', type: "constraint", title: 'Medium', constraint: { table: 'wasmuth_media' }},
    { prop: 'ratecard_id', type: "constraint", title: 'Ratecard', constraint: { table: 'wasmuth_ratecards' }},
    { prop: 'ratecard_number', type: 'text', title: 'Ratecard Number' },
    { type: "text", title: 'Beschreibung', prop: 'description', isNameProperty: true },
    { prop: 'adspecial_type_key', type: "constraint", title: 'Typ', constraint: { table: 'wasmuth_fkt_adspecial_types' }},
    { prop: 'minimum_height', title: 'minimum_height', type: 'int' },
    { prop: 'maximum_height', title: 'maximum_height', type: 'int' },
    { prop: 'minimum_width', title: 'minimum_width', type: 'int' },
    { prop: 'maximum_width', title: 'maximum_width', type: 'int' },
    { prop: 'maximum_weight', title: 'maximum_weight', type: 'int' },

    { prop: 'appearing_weekly_key', type: "constraint", title: 'Wöchentlich', constraint: { table: 'wasmuth_fkt_appearing_weekly' }},

    { prop: 'payment_bit', title: 'payment_bit', type: 'int' },
    { prop: 'by_request', title: 'by_request', type: 'int' },
    { prop: 'splitt', title: 'splitt', type: 'int' },
    { prop: 'splitt_area_bit', title: 'splitt_area_bit', type: 'int' },
    { prop: 'edition_minimum', title: 'edition_minimum', type: 'int' },
    { prop: 'modifydate', title: 'modifydate', type: 'text' },
    { prop: 'sort', title: 'sort', type: 'int' },
    { prop: 'page_format_numeric_value', title: 'page_format_numeric_value', type: 'int' },
    { prop: 'page_format_colour_key', title: 'page_format_colour_key', type: 'int' },
    { prop: 'specimen_copy_count', title: 'specimen_copy_count', type: 'int' },
    { prop: 'processing_addition', title: 'processing_addition', type: 'int' },
  ]
}



