import { Injectable, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  showApiError(error: any, fallbackMsg: string) {
    let message = fallbackMsg

    if (error instanceof HttpErrorResponse) {
      if (error.error.hasOwnProperty('message')) {
        if (error.error.message.startsWith('SQLSTATE[23000]: Integrity constraint violation: 1062 Duplicate entry')) {
          message = "Es gibt bereits einen Datensatz mit den gleichen Verknüpfungen, der neue stellt somit ein Duplikat dar. Duplikate sind bei diesem Datentyp aber nicht erlaubt!"
        } else if (error.error.exception == "Exception") {
          message = error.error.message
        }
      }
    }

    this.showError(message)
  }

  showInfo(text: string) {
    this.show(text, { classname: 'info', delay: 5000 });
  }

  showSuccess(text: string) {
    this.show(text, { classname: 'success', delay: 5000 });
  }

  showError(text: string) {
    this.show(text, { classname: 'error', delay: 0 });
  }

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: string | TemplateRef<any>) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
