<nav class="pager" aria-label="Pagination">
  <ul class="pagination pagination-sm justify-content-center">
    <li class="page-item" [class.disabled]=" ! getFirstPage()">
        <span class="page-link pointer" (click)="setPage(1)" href="#">
          <i-bs name="chevron-double-left"></i-bs>
        </span>
    </li>
    <li class="page-item" [class.disabled]="! getPreviousPage()">
        <span (click)="setPage(getPreviousPage())" class="page-link pointer">
          <i-bs name="chevron-left"></i-bs>
        </span>
    </li>

    <li *ngIf="showPreviousDots()" class="page-item disabled">
      <span class="page-link" aria-disabled="true">...</span>
    </li>

    <li *ngIf="showsAll()" class="page-item disabled">
      <span class="page-link" aria-disabled="true">alle Datensätze</span>
    </li>

    <ng-container *ngFor="let index of getCenterItems()">
      <ng-container *ngIf="(index == currentPage); else notactive">
        <input type="number" [min]="getFirstPage()" [max]="getLastPage()" [style.width]="getInputWidth()" [value]="currentPage" (keyup)="onKeyUp($event)" (change)="jumpTo($event)" />
      </ng-container>
      <ng-template #notactive>
        <li (click)="setPage(index)" class="pointer">
          <span class="page-link">{{ index }}</span>
        </li>
      </ng-template>
    </ng-container>

    <li *ngIf="showNextDots()" class="page-item disabled">
      <span class="page-link" aria-disabled="true">...</span>
    </li>

    <li class="page-item" [class.disabled]="! getNextPage()">
        <span class="page-link pointer" (click)="setPage(getNextPage())">
          <i-bs name="chevron-right"></i-bs>
        </span>
    </li>
    <li class="page-item" [class.disabled]="! getLastPage()">
        <span class="page-link pointer" (click)="setPage(getLastPage())">
          <i-bs name="chevron-double-right"></i-bs>
        </span>
    </li>
  </ul>
</nav>
