import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { FormatModelConfiguration, FormatRecordDataInterface, FormatRecordInterface } from "../models/format";
import { HttpFormatService } from "../services/http/format.service";

@Component({
  selector: 'wefra-Formats',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class FormatsComponent extends AbstractRecordComponent<FormatRecordInterface, FormatRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = FormatModelConfiguration
  api = inject(HttpFormatService)

  constructor(
  ) {
    super()
  }
}
