import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktAdspecialTypeRecordDataInterface extends RecordDataInterface {
  adspecial_type_key: number
  adspecial_type_name: string
  active: number
  de: number
  at: number
  ch: number
}

export interface WasmuthFktAdspecialTypeRecordInterface extends RecordInterface<WasmuthFktAdspecialTypeRecordDataInterface> {
}

export const WasmuthFktAdspecialTypeModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Typ",
  titleSingular: "Typ",
  serviceSlug: "wasmuth_fkt_adspecial_types",
  fields: [
    { type: 'id', title: '#', prop: 'adspecial_type_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'adspecial_type_name', isNameProperty: true },
    { prop: 'active', type: "bool", title: 'Ist Aktiv' },
    { prop: 'de', type: "bool", title: 'DE' },
    { prop: 'at', type: "bool", title: 'AT' },
    { prop: 'ch', type: "bool", title: 'CH' },
  ]
}
