import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgbdModalContent } from "./modal.service.container";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap/modal/modal-config";

export interface ModalChildComponentInputInterface {
  [key: string]: any
}

export interface ModalChildComponentSubscribersInterface {
  [key: string]: Function
}

export interface ModalOptionsInterface {
  title?: string // the title of the modal, if none is given, the header won't be shown
  titleClass?: string // the css class for the title
  infoText?: string  // an optional text, will be shown above the content
  closeBtnText?: string, // the text of the close button. if none is given, the whole footer won't be shown
  inputs?: ModalChildComponentInputInterface // set inputs of the child component
  subscribers?: ModalChildComponentSubscribersInterface,
  modalOptions?: NgbModalOptions
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private modalService: NgbModal,
  ) {
  }

  show(c: any, options: ModalOptionsInterface = {}): NgbModalRef {
    let modalOptions: NgbModalOptions = {}
    if (options.modalOptions) {
      modalOptions = options.modalOptions
    } else {
      modalOptions = {}
    }
    const modalRef = this.modalService.open(NgbdModalContent, modalOptions);
    modalRef.componentInstance.title = options.title ?? '';
    modalRef.componentInstance.titleClass = options.titleClass;
    modalRef.componentInstance.infoText = options.infoText ?? '';
    modalRef.componentInstance.closeBtnText = options.closeBtnText;

    if (typeof c == 'string') {
      modalRef.componentInstance.text = c
    } else if (this.isTemplate(c)) {
      modalRef.componentInstance.content = c
    } else {
      if ("inputs" in options) {
        modalRef.componentInstance.inputs = options.inputs
      }
      if ("subscribers" in options) {
        modalRef.componentInstance.subscribers = options.subscribers
      }
      modalRef.componentInstance.comp = c
    }

    return modalRef
  }

  isTemplate(content: any) { return content instanceof TemplateRef }
}
