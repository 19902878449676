<span [ngbTooltip]="tooltipText" (click)="onClick ? onClick() : undefined">

  <ng-container *ngIf="isImage">
      <img [src]="value" class="rounded pointer" title="{{ title }}"/>
  </ng-container>

  <ng-container *ngIf="isHtml">
    <span [class.pointer]="fieldConfig?.action" class="html" [innerHtml]="html"></span>
  </ng-container>

  <ng-container *ngIf="! isHtml && ! isImage">
    {{ value }}
</ng-container>

</span>
