import { Pipe, PipeTransform } from '@angular/core';
import { renderDateFromString } from "../helpers/main";

@Pipe({
  name: "mydate"
})

export class MyDatePipe implements PipeTransform {
  transform(value: string | undefined) {
    if (typeof value == "undefined") {
      value = '1970-01-01 00:00:00'
    }

    return renderDateFromString(value);
  }
}
