import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktFromToRecordDataInterface extends RecordDataInterface {
  from_to_key: number
  from_to_name: string
}

export interface WasmuthFktFromToRecordInterface extends RecordInterface<WasmuthFktFromToRecordDataInterface> {
}

export const WasmuthFktFromToModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Von bis",
  titleSingular: "Von bis",
  serviceSlug: "wasmuth_fkt_from_to",
  fields: [
    { type: 'id', title: '#', prop: 'from_to_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'from_to_name', isNameProperty: true },
  ]
}
