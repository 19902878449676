import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface SubmarketRecordDataInterface extends RecordDataInterface {
  name: string
  market_id: number
}

export interface SubmarketRecordInterface extends RecordInterface<SubmarketRecordDataInterface> {}

export const SubmarketModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "submarkets",
  allowExportXlsx: true,
  title: "Unterbranchen",
  titleSingular: "Unterbranche",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { type: "constraint", title: 'Branche', prop: 'market_id', constraint: { table: 'markets' }, validators: [ Validators.required ]},
  ]
}

