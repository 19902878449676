import { Component } from '@angular/core';

@Component({
  selector: 'wefra-wasmuth',
  templateUrl: './wasmuth.component.html',
  styleUrls: ['./wasmuth.component.scss']
})
export class WasmuthComponent {

}
