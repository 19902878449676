import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "[click-no-bubble]"
})
export class ClickNoBubble
{
    @HostListener("click", ["$event"])
    public onClick(event: any): void
    {
        event.stopPropagation();
    }
}
