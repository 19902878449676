<form class="m-5" novalidate [formGroup]="form" (ngSubmit)="doLogin(form.value)">
  <div class="mb-3">
    <label class="form-label">E-Mail-Adresse</label>
    <input type="email" class="form-control" formControlName="email">
  </div>
  <div class="mb-3">
    <label class="form-label">Passwort</label>
    <input type="password" class="form-control" formControlName="password">
  </div>
  <div class="mb-3">
    <button type="submit" class="btn btn-green" [disabled]="! form.valid">
      anmelden
    </button>
  </div>
</form>
