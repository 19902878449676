import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface CategoryRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  advertisingform_id: number
  job_ads: number
  mediatype_id: number
  factor: number
  format: number
  numeric_value: number
  substitute_master: number
  master: number
  wasmuth_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface CategoryRecordInterface extends RecordInterface<CategoryRecordDataInterface> {

}

export const CategoryModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "categories",
  allowExportXlsx: true,
  title: "Kategorien",
  titleSingular: "Kategorie",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
    { prop: 'advertisingform_id', type: "constraint", title: 'Werbeform', constraint: { table: 'advertising_forms' }, validators: [ Validators.required ]},
    { prop: 'job_ads', type: "int", title: 'Job Ads' },
    { prop: 'mediatype_id', type: "constraint", title: 'Medientyp', constraint: { table: 'media_types' }, validators: [ Validators.required ]},
    { prop: 'factor', type: "int", title: 'Faktor', validators: [ ]},
    { prop: 'format', type: "int", title: 'Format', validators: [ ]},
    { prop: 'numeric_value', type: "int", title: 'Numerischer Wert', validators: [ ]},
    { prop: 'substitute_master', type: "int", title: 'Ersetze master', validators: [ ]},
    { prop: 'master', type: "int", title: 'Master', validators: [ ]},
    { prop: 'wasmuth_id', type: "int", title: 'Wasmuth ID', validators: [ ]},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

