import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktCostTypeRecordDataInterface,
  WasmuthFktCostTypeRecordInterface
} from "../../models/wasmuth_fkt_cost_type";

@Injectable()
export class HttpWasmuthFktCostTypeService extends HttpAbstractRecordService<WasmuthFktCostTypeRecordInterface, WasmuthFktCostTypeRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_cost_types'
}
