import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface CompanyRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  country: string
  postal_code: number
  city: string
  category_id: number
  vet_number: number
  group_id: number
  company_category_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface CompanyRecordInterface extends RecordInterface<CompanyRecordDataInterface> {

}

export const CompanyModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "companies",
  allowExportXlsx: true,
  title: "Firmen",
  titleSingular: "Firma",
  allowReplace: true,
  allowHistoricize: true,
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
    { prop: 'country', type: "text", title: 'Land', validators: [ Validators.required ]},
    { prop: 'postal_code', type: "int", title: 'Postleitzahl', validators: [ Validators.required ]},
    { prop: 'city', type: "text", title: 'Stadt', validators: [ Validators.required ]},
    // What's that? { prop: 'category_id', type: "constraint", title: 'Kategorie', constraint: { table: 'categories' }, validators: [ ]},
    { prop: 'vet_number', type: "int", title: 'VET Nummer', validators: [ ]},
    { prop: 'group_id', type: "constraint", title: 'Konzern', constraint: { table: 'groups' }, validators: [  ]},
    // { prop: 'company_category_id', type: "constraint", title: 'Firmenkategorie', constraint: { table: 'company_categories' }, validators: [ ]},
    { prop: 'valid_since', type: "date", title: "Gültig ab", validators: [ Validators.required ]},
    { prop: 'valid_till', type: "date", title: "Gültig bis"},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

