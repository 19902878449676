<nav class="pager" aria-label="Pagination">
  <ul class="pagination pagination-sm justify-content-center">

    <li class="page-item" [class.disabled]="currentPageIndex == 0">
      <span class="page-link pointer" (click)="setCurrentPageIndex(0)" href="#">
        <i-bs name="chevron-double-left"></i-bs>
      </span>
    </li>
    <li class="page-item" [class.disabled]="currentPageIndex == 0">
      <span (click)="setCurrentPageIndex(currentPageIndex - 1)" class="page-link pointer">
        <i-bs name="chevron-left"></i-bs>
      </span>
    </li>

    <li *ngIf="(currentPageIndex - 2) > 0" class="page-item disabled">
      <span class="page-link" aria-disabled="true">...</span>
    </li>

    <ng-container *ngFor="let page of pages; let i = index">
      <ng-container *ngIf="(i == currentPageIndex); else notactive">
        <input type="text" [style.width]="getInputWidth()" [value]="currentViewPage" (keyup)="onKeyUp($event)"
               (change)="jumpTo($event)"/>
      </ng-container>
      <ng-template #notactive>
        <li (click)="setCurrentPageIndex(i)" class="pointer" *ngIf="showNavButton(i)">
          <span class="page-link">{{ page }}</span>
        </li>
      </ng-template>
    </ng-container>


    <li *ngIf="currentPageIndex < pages.length - 3" class="page-item disabled">
      <span class="page-link" aria-disabled="true">...</span>
    </li>

    <li class="page-item" [class.disabled]="currentPageIndex >= pages.length">
      <span class="page-link pointer" (click)="setCurrentPageIndex(currentPageIndex + 1)">
        <i-bs name="chevron-right"></i-bs>
      </span>
    </li>
    <li class="page-item" [class.disabled]="currentPageIndex >= pages.length">
      <span class="page-link pointer" (click)="setCurrentPageIndex(pages.length - 1)">
        <i-bs name="chevron-double-right"></i-bs>
      </span>
    </li>
  </ul>
</nav>
