import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
  IndexSignature,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface
} from "../../models/main";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastService } from "../../services/toast.service";
import { AuthService } from "../../services/auth.service";

export interface SaveGenericRecordEventInterface<T extends RecordDataInterface> {
  record: T
  closeEditor: boolean
}

@Component({
  selector: 'wefra-generic-form',
  templateUrl: './generic-form.component.html',
})
export class GenericFormComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {
  @Input({ required: true }) public record?: T
  @Input({ required: true }) public modelConfig?: ModelConfigurationInterface

  @Output("saveRecord") updateRecordEvent = new EventEmitter<SaveGenericRecordEventInterface<K>>()
  @Output("closeClick") closeClickEvent = new EventEmitter<boolean>()

  form?: FormGroup
  idProperty: string = "id"

  constructor(
    @Inject(ToastService) private toastService: ToastService,
    @Inject(AuthService) public authService: AuthService,
    public fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {

    if (! this.modelConfig) return
    if (! this.record) return

    let controls: IndexSignature = {}
    for (const field of this.getFields()) {
      if (field.isIdProperty) this.idProperty = field.prop
      let control = [ this.record.data[field.prop] ?? undefined ]
      if (field.validators) {
        control.push(field.validators)
      }
      controls[field.prop] = control
    }

    let options: IndexSignature = {}
    if (this.modelConfig.validators) {
      options["validators"] = this.modelConfig.validators
    }
    this.form = this.fb.group(controls, options)
  }

  getFields(): ModelConfigurationFieldInterface[] {
    if (! this.modelConfig) return []
    let fields: ModelConfigurationFieldInterface[] = []
    for (let field of this.modelConfig.fields) {
      if (field.ability) {
        if (! this.authService.hasAbility(field.ability)) {
          continue
        }
      }
      fields.push(field)
    }

    return fields
  }

  onClose() {
    this.closeClickEvent.emit(true)
  }

  onCopy() {
    if (! this.record) return
    if (! this.form) return
    if (this.form.controls[this.idProperty]) {
      this.form.controls[this.idProperty]!.setValue(null)
      this.toastService.showSuccess('Kopie erstellt, Sie können den kopierten Datensatz jetzt bearbeiten!')
    }
  }

  onSaveAndClose() {
    if (! this.form) return
    if (! this.form.valid) {
      this.form.markAllAsTouched()
      return
    }

    this.updateRecordEvent.emit({ record: this.form.getRawValue(), closeEditor: true})
  }

  onSave() {
    if (! this.form) return
    if (! this.form.valid) {
      this.form.markAllAsTouched()
      return
    }

    this.updateRecordEvent.emit({ record: this.form.getRawValue(), closeEditor: false})
  }
}
