import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  ProductCategoryModelConfiguration,
  ProductCategoryRecordDataInterface,
  ProductCategoryRecordInterface
} from "../models/product_category";
import { HttpProductCategoryService } from "../services/http/product_category.service";

@Component({
  selector: 'wefra-product-categories',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class ProductCategoriesComponent extends AbstractRecordComponent<ProductCategoryRecordInterface, ProductCategoryRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = ProductCategoryModelConfiguration
  api = inject(HttpProductCategoryService)

  constructor(
  ) {
    super()
  }
}
