import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktColourRecordDataInterface extends RecordDataInterface {
  colour_key: number
  colour_name: string
  colour_bit: number
  colour_short_name: string
}

export interface WasmuthFktColourRecordInterface extends RecordInterface<WasmuthFktColourRecordDataInterface> {
}

export const WasmuthFktColourModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Farbigkeit",
  titleSingular: "Farbigkeit",
  serviceSlug: "wasmuth_fkt_colours",
  fields: [
    { type: 'id', title: '#', prop: 'colour_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'colour_name', isNameProperty: true },
    { prop: 'colour_bit', type: "int", title: 'Farb bit' },
    { prop: 'colour_short_name', type: "text", title: 'Name kurz' },
  ]
}
