import { Component, inject } from '@angular/core';
import { HttpMarketService } from "../services/http/market.service";
import { MarketRecordInterface, MarketModelConfiguration, MarketRecordDataInterface } from "../models/market";
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";

@Component({
  selector: 'wefra-markets',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class MarketsComponent extends AbstractRecordComponent<MarketRecordInterface, MarketRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = MarketModelConfiguration
  api = inject(HttpMarketService)

  constructor(
  ) {
    super()
  }
}
