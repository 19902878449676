import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktUnitRecordDataInterface extends RecordDataInterface {
  unit_key: number
  unit_name: string
}

export interface WasmuthFktUnitRecordInterface extends RecordInterface<WasmuthFktUnitRecordDataInterface> {
}

export const WasmuthFktUnitModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Einheit",
  titleSingular: "Einheit",
  serviceSlug: "wasmuth_fkt_units",
  fields: [
    { type: 'id', title: '#', prop: 'unit_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'unit_name', isNameProperty: true },
  ]
}
