import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktFormRecordDataInterface extends RecordDataInterface {
  form_key: number
  form_name: string
  form_name_short: string
  is_special: number
  is_category: number
  sort: number
  active: number
  de: number
  at: number
  ch: number
}

export interface WasmuthFktFormRecordInterface extends RecordInterface<WasmuthFktFormRecordDataInterface> {
}

export const WasmuthFktFormModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth FKT Forms",
  titleSingular: "Wasmuth FKT Forms",
  serviceSlug: "wasmuth_fkt_forms",
  fields: [
    { type: 'id', title: '#', prop: 'form_key', isIdProperty: true },
    { type: "text", title: 'Name', prop: 'form_name', isNameProperty: true },
    { prop: 'form_name_short', type: "text", title: 'Name kurz' },
    { prop: 'is_special', type: "bool", title: 'Ist speziell' },
    { prop: 'is_category', type: "bool", title: '' },
    { prop: 'active', type: "bool", title: '' },
    { prop: 'de', type: "bool", title: '' },
    { prop: 'at', type: "bool", title: '' },
    { prop: 'ch', type: "bool", title: '' },
    { type: 'int', title: "Sortierung", prop: "sort"},
  ]
}
