import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import {
  WasmuthRatecardNumberRecordDataInterface,
  WasmuthRatecardNumberRecordInterface
} from "../../models/wasmuth_ratecard_numbers";
import { ServiceSlugProperty } from "../../models/main";

@Injectable()
export class HttpWasmuthRatecardNumberService extends HttpAbstractRecordService<WasmuthRatecardNumberRecordInterface, WasmuthRatecardNumberRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_ratecard_numbers'
}
