<form novalidate>
  <p>Dieser Datensatz wird mit dem zu Wählenden historisiert:</p>
  <wefra-multi-form-field
    [fieldConfig]="{ title: '', prop: 'egal', type: 'constraint',
      constraint: { table: serviceSlug }
    }"
    [foreignRecord]="recordToHistoricize"
    [readonly]="true"
    [renderFields]="['name', 'product_id']"
  ></wefra-multi-form-field>

  <p class="mt-3">Wählen Sie den Datensatz, der mit dem obigen Datensatz historisiert werden soll:</p>
  <wefra-multi-form-field
    [fieldConfig]="{ title: 'Datensatz wählen', prop: 'egal', type: 'constraint',
      constraint: { table: serviceSlug }
    }"
    (selectRecord)="onSelectRecordToHistoricizeWith($event)"
    [renderFields]="['name', 'product_id']"
  ></wefra-multi-form-field>
  <div *ngIf="invalid" class="form-field-error"><div>Dieses Feld muss ausgefüllt werden!</div></div>
  <button type="button" (click)="onSubmit()" class="btn btn-green mt-4">Vorschau</button>
  <button type="button" (click)="onCancel()" class="btn btn-red mt-4 ms-3">Abbruch</button>
</form>
