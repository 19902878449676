<div class="row" *ngIf="adSpendings">
  <div class="col-12">
    <table class="w-100">
      <thead>
      <tr>
        <th *ngIf="! readonly"></th>
        <!--          <th>ID</th>-->
        <th class="page">S.</th>
        <th>Typ</th>
        <th>Produkt/Firma</th>
        <th>Motiv</th>
        <th class="multi">Multi</th>
        <th class="type">Platzierung/Werbemittel/Format/Umfang</th>
<!--        <th class="advertisingform_id">Werbeform</th>-->
<!--        <th>Kategorie</th>-->
<!--        <th>Fo.</th>-->
<!--        <th class="color">Fa.</th>-->
        <th class="price">Wert</th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let record of adSpendings">
        <td class="pointer actions" *ngIf="! readonly">
          <span (click)="onEdit(record)">
            <i-bs height="1.2em" width="1.2em" ngbTooltip="Anzeige bearbeiten" name="pencil" />
          </span>
          <span (click)="onCopy(record)">
            <i-bs height="1.2em" width="1.2em" ngbTooltip="Anzeige duplizieren" name="files" />
          </span>
          <span (click)="onDelete(record)">
            <i-bs height="1.2em" width="1.2em" ngbTooltip="Anzeige löschen" name="x-circle" />
          </span>
        </td>
        <!--        <td>{{ record.data.id }}</td>-->
        <td class="page">{{ record.data.page }}</td>
        <td>{{ renderType(record) }}</td>
        <td><strong>{{ renderProductName(record) }}</strong><br />{{ renderCompanyName(record) }}</td>
        <td>{{ renderMotive(record) }}</td>
        <td class="multi">{{ record.data.is_multi ? 'X' : '' }}</td>
        <td class="type">{{ renderPlacement(record) }}</td>
<!--        <td class="advertisingform_id">{{ renderAdvertisingform(record) }}</td>-->
<!--        <td>{{ renderCategory(record) }}</td>-->
<!--        <td>{{ renderFormat(record) }}</td>-->
<!--        <td class="color">{{ renderColor(record) }}</td>-->
        <td class="price">{{ renderPrice(record) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
