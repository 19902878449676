import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FieldRendererContext,
  ModelConfigurationFieldInterface,
  RecordDataInterface,
  RecordInterface
} from "../models/main";
import { HttpServiceFactory } from "../services/http/factory";
import { renderDateFromString } from "../helpers/main";
import { CrudGridComponent } from "./crud-grid.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { MiniGridComponent } from "../mini-grid/mini-grid.component";

export interface EditionHashRenderInterface {
    uri: string
    label: string
}

@Component({
  selector: 'wefra-crud-grid-field-renderer',
  templateUrl: './field-renderer.html',
})
export class CrudGridFieldRendererComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {
  @Input({ required: true }) public record?: T
  @Input({ required: true }) public fieldConfig?: ModelConfigurationFieldInterface
  @Input({ required: false }) public grid?: CrudGridComponent<T, K> | MiniGridComponent<T, K>
  @Input({ required: false }) public context: FieldRendererContext = "crud-grid";

  value?: string
  isImage = false
  isHtml = false
  title?: string
  tooltipText: string = ""
  onClick?: () => void
  html?: SafeHtml

  constructor(
    @Inject(HttpServiceFactory) protected httpServiceFactory: HttpServiceFactory,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    if (! (this.record && this.fieldConfig && this.fieldConfig.prop)) return

    if (this.fieldConfig.hasOwnProperty("action")) {
      this.isHtml = true
      this.onClick = () => {
        if (this.fieldConfig?.action) { // @ts-ignore
          this.fieldConfig.action(this.record, this.grid)
        }
      }
    }

    if (this.fieldConfig.hasOwnProperty("renderer")) {
      try {
        this.html = this.getSanitizedHtml(this.fieldConfig.renderer!(this.record))
        this.isHtml = true
      } catch (e) {
        console.warn('Could not render using custom renderer:', e)
        this.value = this.renderText()
      }
      return
    }

    switch (this.fieldConfig.type) {
      case 'text': this.value = this.renderText()
        break
      case 'constraint':
      case 'multi':
        this.renderConstraint()
        break
      case 'bool': this.value = this.renderBool()
        break
      case 'int':  this.value = this.renderInt()
        break
      case 'editionHash':
        this.isImage = true;
        this.value = this.renderEditionHash().uri
        this.title = this.renderEditionHash().label
        break
      case 'enum':
        this.renderEnum()
        break
      case 'date':
      case 'datetime':
        this.value = this.renderDate()
        break
      case 'json':
        this.isHtml = true
        this.html = this.getSanitizedHtml(this.renderJson())
        break
      default: this.value = this.renderText()
    }
  }

  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  renderDate() {
    if (! this.fieldConfig) return ""
    if (! this.record) return ""
    try {
      return renderDateFromString(this.record.data[this.fieldConfig.prop])
    } catch (e) {
      console.warn(e)
    }
    return ""
  }

  renderEnum(): void {
    if (! this.fieldConfig || ! this.record) {
      this.value = ""
      return
    }

    try {
      if (this.fieldConfig.enums) {
        if (this.context == "mini-grid") {
          this.value = ""
          this.tooltipText = (this.fieldConfig.enums[this.record.data[this.fieldConfig.prop]]?.label ?? '').replaceAll('&shy;', '')
        } else {
          this.value = this.fieldConfig.enums[this.record.data[this.fieldConfig.prop]]?.label ?? ''
        }

        if (this.fieldConfig.enums[this.record.data[this.fieldConfig.prop]]?.class) {
          this.isHtml = true
          this.html = '<span class="' + this.fieldConfig.enums[this.record.data[this.fieldConfig.prop]]?.class + '">' + this.value + '</span>'
        }
      }
    } catch (e) {
      this.value = ''
      this.isHtml = false
    }
  }

  renderJson(): string {
    if (! this.fieldConfig) return ""
    if (! this.record) return ""

    const json = JSON.parse(this.record.data[this.fieldConfig.prop])
    let html = '<dl>'
    for (const jsonElement of Object.entries(json)) {
      html += '<dt>' + jsonElement[0] + '</dt><dd>' + jsonElement[1] + '</dd>'
    }
    html += '</dl>'

    return html
  }

  // renderVirtual() {
  //   if (! this.fieldConfig) return ""
  //   if (! this.record) return ""
  //   if (this.fieldConfig.renderer) {
  //     return this.fieldConfig.renderer(this.record)
  //   }
  //   return ""
  // }

  renderText() {
    if (this.record!.data[this.fieldConfig!.prop]) {
      return this.record!.data[this.fieldConfig!.prop]
    }

    return ""
  }

  renderEditionHash(): EditionHashRenderInterface {
    if (this.record!.data[this.fieldConfig!.prop]) {
      return {
        uri: '/files/editions/' + this.record!.data[this.fieldConfig!.prop] + '/0/small.webp',
        label: this.record?.data['file_basename']
      }
    }

    return { uri: "/assets/placeholder.webp", label: "Fehler beim Laden der Datei" }
  }

  renderInt() {
    if (this.record!.data.hasOwnProperty(this.fieldConfig!.prop)) {
      return this.record!.data[this.fieldConfig!.prop]
    }

    return ""
  }

  renderConstraint() {
    const foreignHttpService = this.httpServiceFactory.getTypeInstance(this.fieldConfig!.constraint!.table)
    if (! foreignHttpService) return this.record!.data[this.fieldConfig!.prop]

    if (! this.fieldConfig) return ""
    if (! this.record) return ""
    if (! this.record.data[this.fieldConfig.prop]) return ""
    if (! this.record.resolved[this.fieldConfig.prop]) return ""

    // fallback to "name"
    let foreignNameProperty = foreignHttpService.getNameProperty()
    if (! foreignNameProperty) foreignNameProperty = "name"

    this.isHtml = false
    this.tooltipText = this.fieldConfig.title + ' (ID): ' + this.record.data[this.fieldConfig.prop]

    if (this.fieldConfig.constraint && this.record.resolved && this.record.resolved[this.fieldConfig.prop]) {
      this.value = this.record.resolved[this.fieldConfig.prop]!.data[foreignNameProperty] ?? this.record!.data[this.fieldConfig!.prop]
      return
    }

    this.value = ''
  }

  renderBool() {
    if (this.record!.data[this.fieldConfig!.prop]) {
      return 'Ja'
    }
    return 'Nein'
  }
}
