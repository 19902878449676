import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { ProductModelConfiguration, ProductRecordDataInterface, ProductRecordInterface } from "../models/product";
import { HttpProductService } from "../services/http/product.service";

@Component({
  selector: 'wefra-products',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class ProductsComponent extends AbstractRecordComponent<ProductRecordInterface, ProductRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = ProductModelConfiguration
  api = inject(HttpProductService)

  constructor(
  ) {
    super()
  }
}
