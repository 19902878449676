/* "Barrel" of Http Interceptors */
/* @see: https://angular.io/guide/http#intercepting-requests-and-responses */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpAuthInterceptor } from "./auth.interceptor";
import { HttpErrorInterceptor } from "./error.interceptor";

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
];
