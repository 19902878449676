import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: "[wheelCtrl]"
})
export class WheelDirective {
  @Output("onSpin") onSpinEvent = new EventEmitter<number>()
  @HostListener("mousewheel", ["$event"]) onMousewheel(event: WheelEvent) {
    if (event.ctrlKey == true) {
      event.stopPropagation()
      this.onSpinEvent.emit(event.deltaY < 0 ? -1 : 1)
    }
  }
}
