import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktAppearingWeeklyRecordDataInterface,
  WasmuthFktAppearingWeeklyRecordInterface
} from "../../models/wasmuth_fkt_appearing_weekly";

@Injectable()
export class HttpWasmuthFktAppearingWeeklyService extends HttpAbstractRecordService<WasmuthFktAppearingWeeklyRecordInterface, WasmuthFktAppearingWeeklyRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_appearing_weekly'
}
