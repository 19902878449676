import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { PdfPaginationService } from "./pdf-pagination.service";

@Component({
  selector: 'wefra-pdf-pagination',
  templateUrl: './pdf-pagination.component.html',
})
export class PdfPaginationComponent {
  @Output("setPage") setPageEvent = new EventEmitter<number>()

  public currentPageIndex: number = 0
  public currentViewPage: string = '1'
  public pages: string[] = []

  constructor(
    @Inject(PdfPaginationService) private pdfPagination: PdfPaginationService,
  ) {
    this.pdfPagination.getPagesSubject().subscribe(pages => {
      this.pages = pages
    })

    this.pdfPagination.getCurrentViewPageSubject().subscribe(vp => {
      this.currentViewPage = vp
    })
    this.pdfPagination.getCurrentPageIndexSubject().subscribe(index => {
      this.currentPageIndex = index
    })
  }

  setCurrentPageIndex(i: number) {
    this.pdfPagination.setCurrentPageIndex(i)
    this.currentPageIndex = i
  }

  showNavButton(pageIndex: number) {
    if (this.pages.length < pageIndex) return false
    if (this.currentPageIndex < (pageIndex + 3) && (this.currentPageIndex > (pageIndex - 3))) {
      return true
    }
    return false
  }

  jumpTo(e: Event) {
    let el = e.target as HTMLInputElement
    const v = el.value
    this.pdfPagination.setCurrentViewPage(v)
    this.setInputWidth(el)
  }

  onKeyUp(e: Event) {
    let el = e.target as HTMLInputElement
    this.setInputWidth(el)
  }

  setInputWidth(el: HTMLInputElement) {
    const width = ((el.value + '').length) + 3
    el.style.width = width + "ch"
  }

  getInputWidth() {
    const width = (this.currentViewPage + '').length + 3
    return width + 'ch'
  }
}
