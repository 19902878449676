import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktPriceTypeRecordDataInterface,
  WasmuthFktPriceTypeRecordInterface
} from "../../models/wasmuth_fkt_price_type";

@Injectable()
export class HttpWasmuthFktPriceTypeService extends HttpAbstractRecordService<WasmuthFktPriceTypeRecordInterface, WasmuthFktPriceTypeRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_price_types'
}
