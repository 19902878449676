import { Component, Inject, inject } from '@angular/core';
import { AbstractRecordComponent } from "../../abstract-records/abstract-record.component";
import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "../../models/main";
import { EditionModelConfiguration, EditionRecordDataInterface, EditionRecordInterface } from "../../models/edition";
import { HttpEditionService } from "../../services/http/edition.service";
import { Router } from "@angular/router";
import { LocalStorageService } from "../../services/localstorage.service";

@Component({
  selector: 'wefra-ad-spending-editions-grid',
  templateUrl: '../../abstract-records/abstract-record.component.html'
})
export class AdSpendingsGridComponent extends AbstractRecordComponent<EditionRecordInterface, EditionRecordDataInterface> {
  modelConfig: ModelConfigurationInterface = EditionModelConfiguration
  api = inject(HttpEditionService)
  override gridPersistentStateKey = "customized"
  constructor(
    private router: Router,
    @Inject(LocalStorageService) private localstorageService: LocalStorageService,
  ) {
    super()
    this.additionalGridActions = [
      { label: "Anzeige erfassen", icon: "clipboard2-plus", method: this.onCaptureAdSpending, identifier: "capture", scope: this, requiredAbility: "spa-user" },
      { label: "Edition bearbeiten", icon: "pencil", method: this.onEditEdition, identifier: "edit", scope: this, requiredAbility: "spa-user" },
      { label: "Edition und dazugehörige Anzeigen", icon: "eye", method: this.onShowEdition, identifier: "show", scope: this },
      { label: "Status setzen", icon: "cup-hot", method: this.onStatusClick, identifier: "status", scope: this, requiredAbility: "spa-user" },
    ]
  }

  public onStatusClick(editionRecord: RecordInterface<RecordDataInterface>) {
    let recordData = editionRecord.data as EditionRecordDataInterface
    if (recordData.capture_state == 2) {
      recordData.capture_state = 1
    } else {
      recordData.capture_state = 2
    }
    this.api.update(recordData).subscribe(result => {
      editionRecord.data["capture_state"] = recordData.capture_state
      if (recordData.capture_state == 2) {
        this.toastService.showSuccess('Der Status wurde erfolgreich auf "Vollständig" gesetzt!')
      } else {
        this.toastService.showSuccess('Der Status wurde erfolgreich auf "In Erfassung" gesetzt!')
      }
    }, error => {
      this.toastService.showError('Der Status konnte nicht aktualisiert werden!')
    }, () => {
      this.gridReloadSubject.next(true)
    })
  }

  public onCaptureAdSpending(editionRecord: RecordInterface<RecordDataInterface>) {
    // remove current edit record
    this.localstorageService.remove('capture_form_record')

    this.router.navigateByUrl("ad-spendings/capture/" + editionRecord.data.id)
  }

  public onEditEdition(editionRecord: RecordInterface<RecordDataInterface>) {
    this.router.navigateByUrl("ad-spendings/edition/" + editionRecord.data.id)
  }

  public onShowEdition(editionRecord: RecordInterface<RecordDataInterface>) {
    this.router.navigateByUrl("edition-and-adspendings/" + editionRecord.data.id)
  }
}
