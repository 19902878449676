import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  CompanyCategoryModelConfiguration,
  CompanyCategoryRecordDataInterface,
  CompanyCategoryRecordInterface
} from "../models/company_category";
import { HttpCompanyCategoryService } from "../services/http/company_category.service";

@Component({
  selector: 'wefra-company-categories',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class CompanyCategoryComponent extends AbstractRecordComponent<CompanyCategoryRecordInterface, CompanyCategoryRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = CompanyCategoryModelConfiguration
  api = inject(HttpCompanyCategoryService)

  constructor(
  ) {
    super()
  }
}
