import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface GroupRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
}

export interface GroupRecordInterface extends RecordInterface<GroupRecordDataInterface> {

}

export const GroupModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "groups",
  allowExportXlsx: true,
  title: "Gruppen",
  titleSingular: "Gruppe",
  defaultSort: [{ field: 'name', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
  ]
}

