import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'wefra-leftnav',
  templateUrl: './leftnav.component.html'
})

export class LeftnavComponent implements OnInit {
  userIsAdmin: boolean = false
  userIsPoweruser: boolean = false
  userIsSpaUser: boolean = false

  constructor(
    @Inject(AuthService) private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.authService.getUserSubject().subscribe(user => {
      if (! user) {
        this.userIsAdmin = false
        return
      }

      if (this.authService.hasAbility("spa-admin")) {
        this.userIsAdmin = true
      } else {
        this.userIsAdmin = false
      }

      if (this.authService.hasAbility("spa-poweruser")) {
        this.userIsPoweruser = true
      } else {
        this.userIsPoweruser = false
      }

      if (this.authService.hasAbility("spa-user")) {
        this.userIsSpaUser = true
      } else {
        this.userIsSpaUser = false
      }
    })
  }
}
