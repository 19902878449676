import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AdSpendingRecordInterface } from "../../models/ad_spending";
import { Router } from "@angular/router";
import { HttpAdSpendingsService } from "../../services/http/ad_spendings.service";

@Component({
  selector: 'wefra-ad-spending-table',
  templateUrl: './table.component.html'
})
export class AdSpendingsTableComponent implements OnInit, OnChanges  {

  @Input() adSpendings?: AdSpendingRecordInterface[]
  @Input() readonly: boolean = false

  @Output("deleteRecord") deleteRecordEvent = new EventEmitter<AdSpendingRecordInterface>()

  constructor(
    private router: Router,
    @Inject(HttpAdSpendingsService) protected adSpendingService: HttpAdSpendingsService
  ) {

  }
  onEdit(record: AdSpendingRecordInterface) {
    if (record.data.edition_id) {
      window.location.href = 'https://' + window.location.host + '/ad-spendings/capture/' + record.data.edition_id + '?adspendingId=' + record.data.id
    }
  }

  onCopy(record: AdSpendingRecordInterface) {
    if (record.data.edition_id) {
      window.location.href = 'https://' + window.location.host + '/ad-spendings/capture/' + record.data.edition_id + '?adspendingId=' + record.data.id + '&copy=true'
    }
  }

  onDelete(record: AdSpendingRecordInterface) {
    this.deleteRecordEvent.emit(record)
  }

  renderType(record: AdSpendingRecordInterface) {
    try {
      if (record.data.is_special) return 'SP'
      if (record.data.use_wasmuth) return 'WA'
      return 'WE'
    } catch (e: any) {
      return ''
    }
  }
  renderCompanyName(record: AdSpendingRecordInterface) {
    try {
      return record.resolved!["company_id"]!.data!["name"]
    } catch (e: any) {
      return ''
    }
  }

  renderProductName(record: AdSpendingRecordInterface) {
    try {
      return record.resolved!["product_id"]!.data!["name"]
    } catch (e: any) {
      return ''
    }
  }

  renderMotive(record: AdSpendingRecordInterface) {
    try {
      return record.resolved!["headline_id"]!.data!["name"]
    } catch (e: any) {
      return ''
    }
  }

  renderPlacement(record: AdSpendingRecordInterface) {
    const a = this.renderAdvertisingform(record)
    const b = this.renderFormat(record)
    const c = this.renderColor(record)

    return a + ', ' + b + c
  }

  renderColor(record: AdSpendingRecordInterface) {
    try {
      if (record.data.use_wasmuth) {
        return record.resolved!['wasmuth_fkt_color_id']!.data!["colour_short_name"]
      } else {
        return record.resolved!['color_id']!.data!["name"]
      }
    } catch (e: any) {
      console.warn(record)
      return ''
    }
  }

  renderAdvertisingform(record: AdSpendingRecordInterface) {
    try {
      let append = ""
      if (record.data["advertisingform_id"] == 3) {
        append = ' (' + record.data["pullout_id"] + ')'
      }

      return record.resolved!["advertisingform_id"]!.data!["name"] + append
    } catch (e: any) {
      return ''
    }
  }

  renderCategory(record: AdSpendingRecordInterface) {
    try {
      if (record.data.use_wasmuth) {
        return record.resolved!['wasmuth_fkt_color_id']!.data!["color_short_name"]
      } else {
        return record.resolved!["category_id"]!.data!["name"]
      }
    } catch (e: any) {
      return ''
    }
  }
  renderPrice(record: AdSpendingRecordInterface) {
    return record.data.price_value + ' €'
  }
  renderFormat(record: AdSpendingRecordInterface) {
    try {

      if (record.data.placement) {
        let placement =  record.data.placement + ' '
        if (record.data.use_wasmuth) {
          if (record.data.is_special == 0) {
            let format = record.resolved['wasmuth_fkt_page_format_code_id']?.data["page_format_code"]
            return placement + format + ', '
          }
        } else {
          let format = record.resolved!["format_id"]!.data!["name"]
          return placement + format + ', '
        }
      }

      if (record.data.use_wasmuth) {
        if (record.data.is_special == 1) {
          let weight = record.resolved['wasmuth_adspecial_cost_id']?.data["weight"]
          if (weight) weight = parseFloat(weight)
          let fromTo = record.resolved['wasmuth_adspecial_cost_id']?.resolved["weight_from_to_key"]?.data["from_to_name"] + ' '
          if (fromTo == 'k.A. ') fromTo = ''
          let unit = record.resolved['wasmuth_adspecial_cost_id']?.resolved["wasmuth_fkt_unit_key"]?.data["unit_name"]

          return fromTo + weight + ' ' + unit + ', '

        } else {
          let description = record.resolved['wasmuth_ad_id']?.data["description"]
          let part1 = record.resolved['wasmuth_fkt_placement_id']?.data["placement_name"]
          if (part1 == 'keine Angabe') {
            if (description && description != "keine Angabe") part1 = description
          } else if (part1 && description) {
            part1 = part1 + ' ' + description
          }

          let part2 = record.resolved['wasmuth_fkt_page_format_code_id']?.data["page_format_code"]
          if (part2 == 'FF') part2 = undefined
          if (!part1 && !part2) return ''

          if (!part1) return part2 + ', '
          if (!part2) return part1 + ', '

          return part1 + ' ' + part2 + ', '
        }

      } else {
        return record.resolved!["format_id"]!.data!["name"] + ', '
      }
    } catch (e: any) {
      return ''
    }
  }

  ngOnInit(): void {
    this.applySort()
  }

  applySort() {
    this.adSpendings?.sort((a, b): number => {
      if (! a.data.page) return -1
      if (! b.data.page) return 1
      let aval: string | number = a.data.page
      let bval: string | number = b.data.page

      if (aval.indexOf('A') == 0) {
        aval = parseInt(aval.substring(1), 10) - 100000
      } else if (aval.indexOf('E') == 0) {
        aval = parseInt(aval.substring(1), 10) + 100000
      } else {
        aval = parseInt(aval)
      }

      if (bval.indexOf('A') == 0) {
        bval = parseInt(bval.substring(1), 10) - 100000
      } else if (bval.indexOf('E') == 0) {
        bval = parseInt(bval.substring(1), 10) + 100000
      } else {
        bval = parseInt(bval)
      }

      if (aval < bval) return -1
      if (aval > bval) return 1

      return  0

    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applySort()
  }
}
