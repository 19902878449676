import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktAdspecialTypeRecordDataInterface,
  WasmuthFktAdspecialTypeRecordInterface
} from "../../models/wasmuth_fkt_adspecial_type";

@Injectable()
export class HttpWasmuthFktAdspecialTypeService extends HttpAbstractRecordService<WasmuthFktAdspecialTypeRecordInterface, WasmuthFktAdspecialTypeRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_adspecial_types'
}
