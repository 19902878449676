import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MagazineListResponseItemInterface } from "../models/main";
import { ToastService } from "../services/toast.service";
import { HttpMagazineService } from "../services/http/magazine.service";
import { MagazineRecordInterface } from "../models/magazine";

@Component({
  selector: 'wefra-monitor-message-component',
  templateUrl: './notice.component.html',
})
export class MonitorNoticeComponent implements OnInit {

  @Output("saveMessage") saveMessageEvent = new EventEmitter<MagazineRecordInterface>()
  @Output("close") closeEvent = new EventEmitter<boolean>()
  @Input({ required: true }) magazineItem?: MagazineListResponseItemInterface
  @Input({ required: true }) readonly: boolean = true

  form?: FormGroup
  notice: string = ''

  constructor(
    public fb: FormBuilder,
    @Inject(HttpMagazineService) private magazineService: HttpMagazineService,
    @Inject(ToastService) private toastService: ToastService
  ) {
  }

  onSubmit(data: { notice: string }) {
    if (! this.magazineItem) return
    this.magazineService.get(this.magazineItem.id).subscribe((magazine: MagazineRecordInterface) => {
      magazine.data.notice = data.notice
      this.magazineService.update(magazine.data).subscribe(updatedMagazine => {
        this.toastService.showSuccess('Die Notiz wurde gespeichert!')
        this.saveMessageEvent.emit(updatedMagazine)
        this.closeEvent.emit(true)
      })
    })
  }

  onCancel() {
    this.closeEvent.emit(true)
  }

  ngOnInit(): void {
    this.load()
  }

  load() {
    if (! this.magazineItem) return

    this.magazineService.get(this.magazineItem.id).subscribe((magazine: MagazineRecordInterface) => {
      this.notice = magazine.data.notice ?? ''
      this.form = this.fb.group({
        notice: [ this.notice ]
      })
    })
  }
}
