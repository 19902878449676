import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { EditionRecordDataInterface, EditionRecordInterface } from "../../models/edition";
import { FileUploadResponseInterface, ServiceSlugProperty } from "../../models/main";

@Injectable()
export class HttpEditionService extends HttpAbstractRecordService<EditionRecordInterface, EditionRecordDataInterface> {
  slug: ServiceSlugProperty = 'editions';

  uploadPdfExtract(formData: FormData) {
    return this.http.post<FileUploadResponseInterface>('/api/editions/uploadPdfExtract', formData)
  }

  getWithoutPublicationDate() {
    return this.http.get<EditionRecordInterface[]>('/api/editions/withoutPublicationDate')
  }

  getLastHandled() {
    return this.http.get<EditionRecordInterface[]>('/api/editions/lastHandled')
  }

}
