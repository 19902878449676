<!--<ng-container *ngIf="isLoading; else loaded">-->
<!--  <div class="d-flex justify-content-center h-100">-->
<!--    <div class="spinner-border" role="status">-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-container>-->
<!--<ng-template #loaded>-->
  <h2 class="ms-4 mb-4">{{ modelConfig.title }}</h2>
    <wefra-crud-grid
       [modelConfig]="modelConfig"
       [api]="api"
       (editRecord)="onEditRecord($event)"
       (deleteRecord)="onDeleteRecord($event)"
       (newRecord)="onNewRecord($event)"
       (viewRecord)="onViewRecord($event)"
       [gridReloadSubject]="gridReloadSubject"
       [additionalActions]="additionalGridActions"
       [actions]="gridActions"
       [showAddRecordButton]="gridShowAddRecordButton"
       [presetFilters]="modelConfig.defaultFilter"
       [persistentStateKey]="gridPersistentStateKey"
    ></wefra-crud-grid>
<!--</ng-template>-->
