import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { MotiveModelConfiguration, MotiveRecordDataInterface, MotiveRecordInterface } from "../models/motive";
import { HttpMotiveService } from "../services/http/motive.service";

@Component({
  selector: 'wefra-motives',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class MotivesComponent extends AbstractRecordComponent<MotiveRecordInterface, MotiveRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = MotiveModelConfiguration
  api = inject(HttpMotiveService)

  constructor(
  ) {
    super()
  }
}
