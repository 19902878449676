import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthAdSizeRecordDataInterface extends RecordDataInterface {
  ad_formats_oid: string
  columns: number
  colour_key: number
  quantity_minimum: number
  quantity_maximum: number
  quantity_unit_key: number
  height_minimum: number
  height_maximum: number
  rows_minimum: number
  rows_maximum: number
  column_count_minimum: number
  column_count_maximum: number
  width_minimum: number
  width_maximum: number
  oid: string
  modifydate: string
}

export interface WasmuthAdSizeRecordInterface extends RecordInterface<WasmuthAdSizeRecordDataInterface> {
}

export const WasmuthAdSizeModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Ad Sizes",
  titleSingular: "Wasmuth Ad Sizes",
  serviceSlug: "wasmuth_ad_sizes",
  fields: [
    { type: 'id', title: '#', prop: 'oid', isIdProperty: true },
    { prop: 'ad_formats_oid', type: 'int', title: 'Ad Formats OID' },
    { prop: 'columns', type: 'int', title: 'Spalten' },
    { prop: 'colour_key', type: 'int', title: 'Farb Key' },
    { prop: 'quantity_minimum', type: 'int', title: 'Anzahl minimum' },
    { prop: 'quantity_maximum', type: 'int', title: 'Anzahl maximum' },
    { prop: 'quantity_unit_key', type: 'int', title: 'Anzahl Einheit' },
    { prop: 'height_minimum', type: 'int', title: 'Höhe minimal' },
    { prop: 'height_maximum', type: 'int', title: 'Höhe maximal' },
    { prop: 'rows_minimum', type: 'int', title: 'Zeilen minimal' },
    { prop: 'rows_maximum', type: 'int', title: 'Zeilen maximal' },
    { prop: 'column_count_minimum', type: 'int', title: 'Anzahl Spalten minimal' },
    { prop: 'column_count_maximum', type: 'int', title: 'Anzahl Spalten maximal' },
    { prop: 'width_minimum', type: 'int', title: 'Breite minimal' },
    { prop: 'width_maximum', type: 'int', title: 'Breite maximal' },
    { prop: 'modifydate', type: 'int', title: 'Änderungsdatum' },
  ]
}



