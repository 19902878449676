import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface WasmuthAdFormatRecordDataInterface extends RecordDataInterface {
  medium_id: string
  ratecard_id: string
  ratecard_number: string
  format_key: number
  format_number: number
  column_type_key: number
  ads_oid: string
  normal_calculation: number
  column_conversion: number
  payment_bit: number
  colour_possibility_bit: number
  oid: string
  modifydate: string
  form_key: number
  form_number: number
  crossover_column_factor: number
  special_conversion_factor: number
}

export interface WasmuthAdFormatRecordInterface extends RecordInterface<WasmuthAdFormatRecordDataInterface> {
}

export const WasmuthAdFormatModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Ad Formats",
  titleSingular: "Wasmuth Ad Formats",
  serviceSlug: "wasmuth_ad_formats",
  fields: [
    { type: 'id', title: '#', prop: 'oid', isIdProperty: true },
    { prop: 'medium_id', type: "constraint", title: 'Medium', constraint: { table: 'wasmuth_media' }, validators: [ Validators.required ]},
    { prop: 'ratecard_id', type: "constraint", title: 'Ratecard', constraint: { table: 'wasmuth_ratecards' }, validators: [ Validators.required ]},
    { prop: 'ratecard_number', type: 'text', title: 'Ratecard Number' },
    { prop: 'format_key', type: 'int', title: 'format_key' },
    { prop: 'format_number', type: 'int', title: 'format_number' },
    { prop: 'column_type_key', type: 'int', title: 'column_type_key' },
    { prop: 'ads_oid', type: 'constraint', title: 'Ads', constraint: { table: 'wasmuth_ads' } },
    { prop: 'normal_calculation', type: 'int', title: 'normal_calculation' },
    { prop: 'column_conversion', type: 'int', title: 'column_conversion' },
    { prop: 'payment_bit', type: 'int', title: 'payment_bit' },
    { prop: 'colour_possibility_bit', type: 'int', title: 'colour_possibility_bit' },
    { prop: 'modifydate', type: 'text', title: 'Änderungsdatum' },
    { prop: 'form_key', type: 'int', title: 'form_key' },
    { prop: 'form_number', type: 'int', title: 'form_number' },
    { prop: 'crossover_column_factor', type: 'int', title: 'crossover_column_factor' },
    { prop: 'special_conversion_factor', type: 'int', title: 'special_conversion_factor' },
  ]
}
