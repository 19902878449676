import { Component } from '@angular/core';

@Component({
  selector: 'wefra-ad-spending-edition-show-grid',
  templateUrl: './show.component.html'
})
export class EditionShowComponent {

  constructor(
  ) {
  }
}
