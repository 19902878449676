import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import { PubdateAliasRecordDataInterface, PubdateAliasRecordInterface } from "../../models/pubdate_alias";

@Injectable()
export class HttpPubdateAliasService extends HttpAbstractRecordService<PubdateAliasRecordInterface, PubdateAliasRecordDataInterface> {
  slug: ServiceSlugProperty = 'pubdate_aliases'
}

