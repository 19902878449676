import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthAdspecialCostRecordDataInterface extends RecordDataInterface {
  adspecials_oid: string
  price_type_key: number
  adspecial_cost_type_key: number
  weight: number
  appearing_weekly_key: number
  price: number
  weight_from_to_key: number
  oid: string
  modifydate: string
  price_zone: number
  edition_number: number
  weight_unit_key: number
}

export interface WasmuthAdspecialCostRecordInterface extends RecordInterface<WasmuthAdspecialCostRecordDataInterface> {
}

export const WasmuthAdspecialCostModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Ad Costs",
  titleSingular: "Wasmuth Ad Costs",
  serviceSlug: "wasmuth_ad_costs",
  fields: [
    { type: 'id', title: '#', prop: 'oid', isIdProperty: true },
    { prop: 'price_type_key', type: 'constraint', title: 'price_type_key', constraint: { table: 'wasmuth_fkt_price_types' } },
    { prop: 'appearing_weekly_key', type: 'constraint', title: 'appearing_weekly_key', constraint: { table: 'wasmuth_fkt_appearing_weekly' } },
    { prop: 'adspecials_oid', type: 'constraint', title: 'Adspecial', constraint: { table: 'wasmuth_adspecials' } },
    { prop: 'adspecial_cost_type_key', type: 'constraint', title: 'Kostentyp', constraint: { table: 'wasmuth_fkt_adspecial_cost_types' } },
    { prop: 'weight', type: 'text', title: 'weight' },
    { prop: 'price', type: 'text', title: 'price', isNameProperty: true },

    { prop: 'weight_from_to_key', type: 'constraint', title: 'weight von/bis', constraint: { table: 'wasmuth_fkt_from_to' } },

    { prop: 'modifydate', type: 'text', title: 'modifydate' },
    { prop: 'price_zone', type: 'text', title: 'price_zone' },
    { prop: 'edition_number', type: 'text', title: 'edition_number' },
    { prop: 'weight_unit_key', type: 'text', title: 'weight_unit_key' },
  ]
}
