import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface ImportedCirculationRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  circulation_print: number
  valid_from: string
  valid_to: string
  medium_id: number
  quarter: number
  year: number
  title_number: string
}

export interface ImportedCirculationRecordInterface extends RecordInterface<ImportedCirculationRecordDataInterface> {
}

export const ImportedCirculationModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "imported_circulations",
  allowExportXlsx: false,
  title: "Importierte Auflagen",
  titleSingular: "Importierte Auflage",
  defaultSort: [],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    // { prop: 'medium_id', renderer: (record: RecordInterface<RecordDataInterface>) => {
    //     return record.resolved["medium_id"]?.data["name"]
    //   }, type: "constraint", title: 'Magazin', constraint: { table: 'magazines' },
    //   validators: [ Validators.required ], filterProperty: "magazineId.name", isNameProperty: true
    // },
    { prop: 'medium_id', type: "int", title: "Medium ID" },
    { prop: 'valid_from', type: "date", title: "Gültig ab", validators: [ Validators.required ]},
    { prop: 'valid_to', type: "date", title: "Gültig bis"},
    { prop: 'name', type: "text", isNameProperty: true, title: "Magazin"},
    { prop: 'circulation_print', type: "int", title: "Druckauflage" },

    { prop: 'quarter', type: "int", title: "Quartal" },
    { prop: 'year', type: "int", title: "Jahr" },
    { prop: 'title_number', type: "int", title: "Titelnummer" },


  ]
}

