import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import {
  WasmuthMediaRatecardRecordDataInterface,
  WasmuthMediaRatecardRecordInterface
} from "../../models/wasmuth_media_ratecards";
import { ServiceSlugProperty } from "../../models/main";

@Injectable()
export class HttpWasmuthMediaRatecardService extends HttpAbstractRecordService<WasmuthMediaRatecardRecordInterface, WasmuthMediaRatecardRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_media_ratecards'
}
