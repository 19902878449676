import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthRequestInterface, AuthResponseInterface, LogoutResponseInterface } from "../../models/auth";
import { HttpBaseService } from './base.service';

@Injectable()
export class HttpAuthService extends HttpBaseService {

  login(credentials: AuthRequestInterface) : Observable<AuthResponseInterface> {
    const url: string = this.getMainApiBaseUrl() + "auth/login"
    return this.http.post<AuthResponseInterface>(url, credentials)
  }

  logout() : Observable<LogoutResponseInterface> {
    const url: string = this.getMainApiBaseUrl() + "auth/logout"
    return this.http.post<LogoutResponseInterface>(url, '')
  }

  refresh() : Observable<AuthResponseInterface> {
    const url: string = this.getMainApiBaseUrl() + "auth/refresh"
    return this.http.post<AuthResponseInterface>(url, '')
  }
}
