import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { AdSpendingRecordInterface } from "../models/ad_spending";
import { LocalStorageService } from "./localstorage.service";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private currentCaptureSubject: BehaviorSubject<AdSpendingRecordInterface | undefined>
  constructor(
    @Inject(LocalStorageService) private storageService: LocalStorageService
  ) {
    this.currentCaptureSubject = new BehaviorSubject<AdSpendingRecordInterface | undefined>(undefined)
    let lsrecord = this.storageService.get("capture_form_record")
    this.currentCaptureSubject.next(lsrecord)
  }
  getCurrentCaptureSubject() {
    return this.currentCaptureSubject
  }
}
