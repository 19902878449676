import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { MagazineModelConfiguration, MagazineRecordDataInterface, MagazineRecordInterface } from "../models/magazine";
import { HttpMagazineService } from "../services/http/magazine.service";

@Component({
  selector: 'wefra-magazines',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class MagazinesComponent extends AbstractRecordComponent<MagazineRecordInterface, MagazineRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = MagazineModelConfiguration
  api = inject(HttpMagazineService)

  constructor(
  ) {
    super()
    this.additionalGridActions = [
      {label: "indexieren", method: this.onIndexMagazine, icon: "boxes", identifier: 'index', scope: this, requiredAbility: 'spa-poweruser'},
    ]
  }

  public onIndexMagazine(record: RecordInterface<RecordDataInterface>) {
    const castrecord = record as MagazineRecordInterface
    if (! castrecord.data['wasmuth_id']) {
      this.toastService.showError('Das Magazin hat keine Ratecard zugewiesen, somit ist auch keine Indexierung möglich.')
      return
    }
    this.api.index(castrecord.data).subscribe(result => {
      this.toastService.showSuccess('Das Magazin wurde erfolgreich indexiert!')
    }, error => {
      this.toastService.showError(error.error.message)
    })
  }
}
