<div class="pagewrap">
  <header>
    <wefra-navigation [authenticated]="authenticated"></wefra-navigation>
  </header>
  <div class="main">
    <ng-container *ngIf="authenticated; else loginform">
      <div class="leftnav-container">
        <wefra-leftnav></wefra-leftnav>
      </div>

      <div class="application-container">
        <router-outlet></router-outlet>
      </div>
    </ng-container>
    <ng-template #loginform>
      <wefra-login></wefra-login>
    </ng-template>
  </div>
</div>
<wefra-toasts aria-live="polite" aria-atomic="true"></wefra-toasts>
