import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface WasmuthAdRecordDataInterface extends RecordDataInterface {
  medium_id: string
  ratecard_id: string
  ratecard_number: string
  form_key: number
  column_type_key: number
  placement_key: number
  page_format_code: string
  orientation_type_key: number
  bleed: number
  crossover: number
  appearing_weekly_key: number
  by_request: number
  description: string
  oid: string
  modifydate: string
  form_number: number
}

export interface WasmuthAdRecordInterface extends RecordInterface<WasmuthAdRecordDataInterface> {
}

export const WasmuthAdModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Ads",
  titleSingular: "Wasmuth Ads",
  serviceSlug: "wasmuth_ads",
  fields: [
    { type: 'id', title: '#', prop: 'oid', isIdProperty: true },
    { prop: 'medium_id', type: "constraint", title: 'Medium', constraint: { table: 'wasmuth_media' }, validators: [ Validators.required ]},
    { prop: 'ratecard_id', type: "constraint", title: 'Ratecard', constraint: { table: 'wasmuth_ratecards' }, validators: [ Validators.required ]},
    { prop: 'ratecard_number', type: 'text', title: 'Ratecard Number' },
    { prop: 'form_key', type: "constraint", title: 'Form', constraint: { table: 'wasmuth_fkt_forms' }, validators: [ Validators.required ]},
    // column_type_key
    { prop: 'placement_key', type: "constraint", title: 'Placement', constraint: { table: 'wasmuth_fkt_placements' }, validators: [ Validators.required ]},
    // { prop: 'page_format_code', type: "constraint", title: 'Medientyp', constraint: { table: 'media_types' }, validators: [ Validators.required ]},
    // { prop: 'orientation_type_key', type: "constraint", title: 'Medientyp', constraint: { table: 'media_types' }, validators: [ Validators.required ]},
    { type: "bool", title: 'Bleed', prop: 'bleed' },
    { type: "bool", title: 'Crossover', prop: 'crossover' },
    // { prop: 'appearing_weekly_key', type: "constraint", title: 'Medientyp', constraint: { table: 'media_types' }, validators: [ Validators.required ]},
    { type: "text", title: 'Beschreibung', prop: 'description', isNameProperty: true },
    { type: "bool", title: 'By Request', prop: 'by_request' },
    // modifydate
    // form_number
  ]
}



