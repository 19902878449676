import { Component, inject } from '@angular/core';
import { AbstractRecordComponent } from "../../../abstract-records/abstract-record.component";
import { ModelConfigurationInterface } from "../../../models/main";
import {
  WasmuthMediaModelConfiguration,
  WasmuthMediaRecordDataInterface,
  WasmuthMediaRecordInterface
} from "../../../models/wasmuth_media";
import { HttpWasmuthMediaService } from "../../../services/http/wasmuth_media.service";

@Component({
  selector: 'wefra-wasmuth-tables-media',
  templateUrl: '../../../abstract-records/abstract-record.component.html'
})
export class WasmuthTablesMediaComponent extends AbstractRecordComponent<WasmuthMediaRecordInterface, WasmuthMediaRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = WasmuthMediaModelConfiguration
  api = inject(HttpWasmuthMediaService)

  constructor(
  ) {
    super()
  }
}
