import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  ImportedCirculationRecordDataInterface,
  ImportedCirculationRecordInterface
} from "../../models/imported_circulation";

@Injectable()
export class HttpImportedCirculationService extends HttpAbstractRecordService<ImportedCirculationRecordInterface, ImportedCirculationRecordDataInterface> {
  slug: ServiceSlugProperty = 'imported_circulations'
}
