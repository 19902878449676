<input [value]="viewValue" class="form-control" type="text" [ngbTooltip]="tooltipText"
   (focusin)="onFocusIn($event)"
   (focusout)="onFocusOut($event)"
   (keyup)="onKeyUp($event)"
   (keydown.enter)="onKeyEnter($event)"
>
<ng-container *ngIf="! this.readonly">
  <i-bs *ngIf="searching" name="arrow-clockwise" />
  <i-bs *ngIf="! searching" name="card-list" (click)="openGridSelectorModal()" />
  <i-bs class="clear" *ngIf="viewValue" name="x" (click)="clearField()" />
</ng-container>

<div class="list" *ngIf="searchItems">
  <div class="items" #searchResults>
    <ng-container *ngFor="let searchItem of searchItems; let i = index">
      <div class="item"
        [class.active]="keyboardNavigationIndex == i"
        (mousedown)="selectRecord(searchItem)"
      >
        {{ getVisibleName(searchItem) }}
      </div>
    </ng-container>
  </div>
</div>
