import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export abstract class HttpBaseService {

  constructor(
    protected http: HttpClient
  ) { }

  getMainApiBaseUrl() {
    return window.location.protocol + '//' + window.location.hostname + '/api/'
  }
}
