import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface WefraPubdateRecordDataInterface extends RecordDataInterface {
  id: number
  pubdate: string
  edition_number: string
  wefra_ratecard_id: number
}

export interface WefraPubdateRecordInterface extends RecordInterface<WefraPubdateRecordDataInterface> {
}

export const WefraPubdateModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "wefra_pubdates",
  allowExportXlsx: true,
  title: "Erscheinungsdatum",
  titleSingular: "Erscheinungsdatum",
  defaultSort: [],
  fields: [
    { prop: 'id', type: 'id', title: '#', isIdProperty: true, edit: false },
    { prop: 'pubdate', type: "date", title: "Erscheinungsdatum", validators: [ Validators.required ]},
    { prop: 'edition_number', type: "text", title: 'Ausgabe', isNameProperty: true, validators: [ Validators.required ]},
    //{ prop: 'wefra_ratecard_id', type: "constraint", title: 'Wefra Ratecard', constraint: { table: 'wefra_ratecards' }, validators: [ Validators.required ]},
    { prop: 'wefra_ratecard_id', renderer: (record: RecordInterface<RecordDataInterface>) => {
        return record.resolved["wefra_ratecard_id"]?.resolved["magazine_id"]?.data["name"]
      }, type: "constraint", title: 'Wefra Ratecard', constraint: { table: 'wefra_ratecards' },
      validators: [ Validators.required ], filterProperty: "wefraRatecardId.magazineId.name", isNameProperty: true
    },


  ]
}

