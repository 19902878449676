import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface MediaTypeRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  wasmuth_id: number
}

export interface MediaTypeRecordInterface extends RecordInterface<MediaTypeRecordDataInterface> {

}

export const MediaTypeModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "media_types",
  allowExportXlsx: true,
  title: "Medientypen",
  titleSingular: "Medientyp",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
    { prop: 'wasmuth_id', type: "constraint", title: 'Wasmuth Medium', constraint: { table: 'wasmuth_media' }, validators: [ Validators.required ]},
  ]
}

