import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface PublisherRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  email: string
  phone_number: string
  notes: string
  wasmuth_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface PublisherRecordInterface extends RecordInterface<PublisherRecordDataInterface> {
}

export const PublisherModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "publishers",
  allowExportXlsx: true,
  title: "Verlage",
  titleSingular: "Verlag",
  allowHistoricize: false,
  defaultSort: [{ field: 'name', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { type: "text", title: 'E-Mail', prop: 'email', validators: [ ]},
    { type: "text", title: 'Telefonnummer', prop: 'phone_number', validators: [ ]},
    { type: "text", title: 'Notizen', prop: 'notes', validators: [ ]},
    { type: "constraint", title: 'Wasmuth ID', prop: 'wasmuth_id', constraint: { table: 'wasmuth_publishers' }, validators: [ ]},
    { prop: 'valid_since', type: "date", title: "Gültig ab", validators: [ Validators.required ]},
    { prop: 'valid_till', type: "date", title: "Gültig bis"},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}
