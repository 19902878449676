
<div class="dlselector" [class.norecord]="! foreignRecord" (click)="openGridModalIfNoRecord()">
  <ng-container *ngIf="selectedRecordTemplate; else genericSelectedRecordTemplate">
    <ng-container *ngTemplateOutlet="selectedRecordTemplate">
    </ng-container>
  </ng-container>

  <ng-template #genericSelectedRecordTemplate>
    <dl *ngIf="renderFields && renderFields.length && foreignRecord">
      <ng-container *ngFor="let field of modelConfig?.fields">
        <ng-container *ngIf="renderFields?.includes(field.prop)">
          <dt>{{ field.title }}</dt>
          <dd>
            <wefra-crud-grid-field-renderer [record]="foreignRecord" [fieldConfig]="field" />
          </dd>
        </ng-container>
      </ng-container>
    </dl>
  </ng-template>

  <ng-container *ngIf="! readonly">
    <i-bs name="card-list" (click)="openGridModal()" />
    <i-bs class="clear" *ngIf="foreignRecord" name="x" click-no-bubble (click)="clearField($event)" />
  </ng-container>
</div>



