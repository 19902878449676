import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import {
  ElasticsearchListResponseInterface,
  ESQueryInterface,
  KeyValueInterface,
  PriceQueryDataInterface,
  SortingInterface
} from "../../models/main";


@Injectable()
export class HttpElasticsearchService {
  url: string = ''

  constructor(
    protected http: HttpClient,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.url = window.location.protocol + '//' + window.location.hostname + '/elasticsearch/_search'
    }
  }

  search(ratecardId: number, ratecardNumber: string, filters: ESQueryInterface[], sort?: SortingInterface[]) {
    return this.http.post<ElasticsearchListResponseInterface<PriceQueryDataInterface>>(this.url, {
      from: 0,
      size: 1000,
      sort: this.getSortForQuery(sort),
      query: {
        bool: {
          must: [{
            bool: {
              must: [
                {
                  match: {
                    ratecard_id: {
                      query: ratecardId,
                    }
                  },
                }, {
                  match: {
                    ratecard_number: {
                      query: ratecardNumber,
                    }
                  }
                },
                ...filters,
              ]
            }}
          ]
        }
      },
    })
  }

  protected getSortForQuery(s?: SortingInterface[]) {
    let sort: KeyValueInterface[] = []
    if (! s) return sort
    for (const item of Object.values(s)) {
      let o: KeyValueInterface = {}
      if (item.field && item.dir){
        o[item.field] = item.dir
        sort.push(o)
      }
    }

    return sort
  }
}
