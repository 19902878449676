import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthMediaEditionRecordDataInterface extends RecordDataInterface {
  medium_id: string
  ratecard_id: string
  ratecard_number: string
  edition_type_key: number
  appearing_weekly_key: number
  edition: number
  source: string
  oid: string
  modifydate: string
}

export interface WasmuthMediaEditionRecordInterface extends RecordInterface<WasmuthMediaEditionRecordDataInterface> {
}

export const WasmuthMediaEditionModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Media Editions",
  titleSingular: "Wasmuth Media Editions",
  serviceSlug: "wasmuth_media_editions",
  fields: [
    { prop: 'medium_id', type: "constraint", title: 'Medium', constraint: { table: 'wasmuth_media' }},
    { prop: 'ratecard_id', type: 'text', title: 'RC ID' },
    { prop: 'ratecard_number', type: 'text', title: 'RC Number' },
    { prop: 'edition_type_key', type: "int", title: "Typ" },
    { prop: 'edition', type: "int", title: "Auflage" },
  ]
}

