import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthFktCostTypeRecordDataInterface extends RecordDataInterface {
  cost_type_key: number
  cost_type_name: string
  is_surcharge: number
  is_color_surcharge: number
  is_bleed_surcharge: number
  is_cross_over_surcharge: number
  is_bw_base: number
  is_base: number
  unit_key: number
  unit_count: number
  is_fix_cost: number
  appearing_weekly_key: number
}

export interface WasmuthFktCostTypeRecordInterface extends RecordInterface<WasmuthFktCostTypeRecordDataInterface> {
}

export const WasmuthFktCostTypeModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Farbigkeit",
  titleSingular: "Farbigkeit",
  serviceSlug: "wasmuth_fkt_cost_types",
  fields: [
    { type: 'id', title: '#', prop: 'cost_type_key', isIdProperty: true },
    { prop: 'cost_type_name', type: "text", title: 'Name', isNameProperty: true },

    { prop: 'is_surcharge', type: "int", title: 'is_surcharge' },
    { prop: 'is_color_surcharge', type: "int", title: 'is_color_surcharge' },
    { prop: 'is_bleed_surcharge', type: "int", title: 'is_bleed_surcharge' },
    { prop: 'is_cross_over_surcharge', type: "int", title: 'is_cross_over_surcharge' },
    { prop: 'is_bw_base', type: "int", title: 'is_bw_base' },
    { prop: 'is_base', type: "int", title: 'is_base' },
    { prop: 'unit_key', type: "int", title: 'unit_key' },
    { prop: 'unit_count', type: "int", title: 'unit_count' },
    { prop: 'is_fix_cost', type: "int", title: 'is_fix_cost' },
    { prop: 'appearing_weekly_key', type: "int", title: 'appearing_weekly_key' },
  ]
}
