import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../auth.service";

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  authService: AuthService
  constructor(
    @Inject(AuthService) authService: AuthService
  ) {
    this.authService = authService
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken()
    if (token) {
      const req = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
      return next.handle(req)
    }

    return next.handle(request)
  }
}
