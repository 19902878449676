import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthAdspecialCostRecordDataInterface,
  WasmuthAdspecialCostRecordInterface
} from "../../models/wasmuth_adspecial_cost";

@Injectable()
export class HttpWasmuthAdspecialCostService extends HttpAbstractRecordService<WasmuthAdspecialCostRecordInterface, WasmuthAdspecialCostRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_adspecial_costs'
}
