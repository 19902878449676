<form novalidate>
  <p>Dieser Datensatz wird ersetzt:</p>
  <wefra-multi-form-field
    [fieldConfig]="{ title: '', prop: 'egal', type: 'constraint',
      constraint: { table: serviceSlug }
    }"
    [foreignRecord]="recordToReplace"
    [readonly]="true"
    [renderFields]="['name', 'product_id']"
  ></wefra-multi-form-field>

  <p class="mt-3">Wählen Sie den Datensatz, der erhalten bleibt und den obigen Datensatz ersetzen soll:</p>
  <wefra-multi-form-field
    [fieldConfig]="{ title: 'Datensatz wählen', prop: 'egal', type: 'constraint',
      constraint: { table: serviceSlug }
    }"
    (selectRecord)="onSelectRecordToKeep($event)"
    [renderFields]="['name', 'product_id']"
  ></wefra-multi-form-field>
  <div *ngIf="invalid" class="form-field-error"><div>Dieses Feld muss ausgefüllt werden!</div></div>
  <button type="button" (click)="onSubmit()" class="btn btn-green mt-4">Ersetzen</button>
</form>
