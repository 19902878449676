import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import {
  WasmuthPublicationDateRecordDataInterface,
  WasmuthPublicationDateRecordInterface
} from "../../models/wasmuth_publication_dates";
import { Observable } from "rxjs";
import { ServiceSlugProperty } from "../../models/main";

@Injectable()
export class HttpWasmuthPublicationDateService extends HttpAbstractRecordService<WasmuthPublicationDateRecordInterface, WasmuthPublicationDateRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_publication_dates'

  getComposed(medium_id: number, ratecard_id: number, ratecard_number: string): Observable<WasmuthPublicationDateRecordInterface> {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/mediumId/' + medium_id + '/ratecardId/' + ratecard_id + '/ratecardNumber/' + ratecard_number
    return this.http.get<WasmuthPublicationDateRecordInterface>(url)
  }
}
