import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthRatecardNumberRecordDataInterface extends RecordDataInterface {
  id: number
  publisher_id: number
  ratecard_name: string
}

export interface WasmuthRatecardNumberRecordInterface extends RecordInterface<WasmuthRatecardNumberRecordDataInterface> {
}

export const WasmuthRatecardNumberModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth RatecardNumber",
  titleSingular: "Wasmuth RatecardNumber",
  serviceSlug: "wasmuth_ratecard_numbers",
  fields: [
    { type: 'id', title: '#', prop: 'ratecard_id', isIdProperty: true },
    // todo: define constraint { prop: 'publisher_id', type: "constraint", title: 'Publisher', constraint: { table: 'wasmuth_publisher' }},
    { type: 'int', title: "Publisher", prop: "publisher_id"},
    { type: "text", title: 'Nummer', prop: 'ratecard_number', isNameProperty: true },

    //
    // { prop: 'ratecard_id', type: 'text', title: 'RatecardNumber ID' },
    // { prop: 'ratecard_number', type: 'text', title: 'RatecardNumber Number' },
    //
    //
    // { type: "text", title: 'Name 2', prop: 'name2' },
    // { type: "text", title: 'Initial', prop: 'initial' },
    // { type: "text", title: 'Name Lang', prop: 'name_long' },
    // { type: "text", title: 'Edition', prop: 'edition' },
    // { type: "text", title: 'Edition Lang', prop: 'edition_long' },
    // { type: "datetime", title: 'Letzte Änderung', prop: 'modifydate' },
  ]
}

