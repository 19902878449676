import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { CompanyModelConfiguration, CompanyRecordDataInterface, CompanyRecordInterface } from "../models/company";
import { HttpCompanyService } from "../services/http/company.service";

@Component({
  selector: 'wefra-companies',
  templateUrl: '../abstract-records/abstract-record.component.html'
})
export class CompaniesComponent extends AbstractRecordComponent<CompanyRecordInterface, CompanyRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = CompanyModelConfiguration
  api = inject(HttpCompanyService)

  constructor(
  ) {
    super()
  }
}
