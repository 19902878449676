import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthMediaEditionRecordDataInterface,
  WasmuthMediaEditionRecordInterface
} from "../../models/wasmuth_media_editions";

@Injectable()
export class HttpWasmuthMediaEditionService extends HttpAbstractRecordService<WasmuthMediaEditionRecordInterface, WasmuthMediaEditionRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_media_editions'
}
