import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { MagazineRecordDataInterface, MagazineRecordInterface } from "../../models/magazine";
import { ServiceSlugProperty } from "../../models/main";

@Injectable()
export class HttpMagazineService extends HttpAbstractRecordService<MagazineRecordInterface, MagazineRecordDataInterface> {
  slug: ServiceSlugProperty = 'magazines'

  index(record: MagazineRecordDataInterface) {
    return this.http.post(this.getMainApiBaseUrl() + 'elasticsearch/magazines/index', record)
  }
}
