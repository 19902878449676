import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface TargetAudienceRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  mediatype_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface TargetAudienceRecordInterface extends RecordInterface<TargetAudienceRecordDataInterface> {
}

export const TargetAudienceModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "target_audiences",
  allowExportXlsx: true,
  title: "Zielgruppen",
  titleSingular: "Zielgruppe",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { type: "constraint", title: 'Medientyp', prop: 'mediatype_id', constraint: { table: 'media_types' }, validators: [ Validators.required ]},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}
