import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthAdCostRecordDataInterface extends RecordDataInterface {
  ad_formats_oid: string
  price_zone: number
  edition_number: number
  appearing_weekly_key: number
  price_type_key: number
  colour_key: number
  quantity: number
  columns: number
  cost_type_key: number
  quantity_from_to_key: number
  quantity_unit_key: number
  cost_value: number
  cost_percent: number
  oid: string
  modifydate: string
  option_bit: number
}

export interface WasmuthAdCostRecordInterface extends RecordInterface<WasmuthAdCostRecordDataInterface> {
}

export const WasmuthAdCostModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Ad Costs",
  titleSingular: "Wasmuth Ad Costs",
  serviceSlug: "wasmuth_ad_costs",
  fields: [
    { type: 'id', title: '#', prop: 'oid', isIdProperty: true },
    { prop: 'ad_formats_oid', type: 'constraint', title: 'Ad Formats OID', constraint: { table: 'wasmuth_ad_formats' } },

    { prop: 'appearing_weekly_key', type: 'constraint', title: 'appearing_weekly_key', constraint: { table: 'wasmuth_fkt_appearing_weekly' } },
    { prop: 'colour_key', type: 'constraint', title: 'Farbigkeit', constraint: { table: 'wasmuth_fkt_colours' } },
    { prop: 'cost_type_key', type: 'constraint', title: 'Kostentyp', constraint: { table: 'wasmuth_fkt_cost_types' } },
    { prop: 'quantity_from_to_key', type: 'constraint', title: 'quantity_from_to_key', constraint: { table: 'wasmuth_fkt_from_to' } },
    { prop: 'price_type_key', type: 'constraint', title: 'Preistyp', constraint: { table: 'wasmuth_fkt_price_types' } },
    { prop: 'quantity_unit_key', type: 'constraint', title: 'quantity_unit_key', constraint: { table: 'wasmuth_fkt_units' } },

    { prop: 'price_zone', type: 'int', title: 'price_zone' },
    { prop: 'edition_number', type: 'int', title: 'edition_number' },
    { prop: 'quantity', type: 'int', title: 'quantity' },
    { prop: 'columns', type: 'int', title: 'columns' },

    { prop: 'cost_percent', type: 'int', title: 'cost_percent' },

    { prop: 'option_bit', type: 'int', title: 'option_bit' },
    { prop: 'modifydate', type: 'text', title: 'Änderungsdatum' },


    { prop: 'placement_id', type: 'virtual', title: 'Platzierung', renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['ad_formats_oid'].resolved['ads_oid'].resolved['placement_key'].data["placement_name"]) {
            // @ts-ignore
            return record.resolved['ad_formats_oid'].resolved['ads_oid'].resolved['placement_key'].data["placement_name"]
          }
        } catch (e) {
          return '<keine Angabe>'
        }
        return '<keine Angabe>'
    }, filterProperty: 'adFormatsOid.adsOid.placementKey.placement_name', disableSort: true },
    { prop: 'orientation_type_key', type: 'virtual', title: 'Orientierung', renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['ad_formats_oid'].resolved['ads_oid'].resolved['orientation_type_key'].data["orientation_type_name"]) {
            // @ts-ignore
            return record.resolved['ad_formats_oid'].resolved['ads_oid'].resolved['orientation_type_key'].data["orientation_type_name"]
          }
        } catch (e) {
          return '<keine Angabe>'
        }
        return '<keine Angabe>'
      }, filterProperty: 'adFormatsOid.adsOid.orientationTypeKey.orientation_type_name', disableSort: true },
    { prop: 'page_format_id', type: 'virtual', title: 'Format', renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['ad_formats_oid'].resolved['ads_oid'].data["page_format_code"]) {
            // @ts-ignore
            return record.resolved['ad_formats_oid'].resolved['ads_oid'].data["page_format_code"]
          }
        } catch (e) {
          return '<keine Angabe>'
        }
        return '<keine Angabe>'
    }, filterProperty: 'adFormatsOid.adsOid.page_format_code', disableSort: true },
    { prop: 'fkt_form_id', type: 'virtual', title: 'Anzeigenform', renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['ad_formats_oid'].resolved['ads_oid'].resolved["form_key"].data["form_name"]) {
            // @ts-ignore
            return record.resolved['ad_formats_oid'].resolved['ads_oid'].resolved["form_key"].data["form_name"]
          }
        } catch (e) {
          return '<keine Angabe>'
        }
        return '<keine Angabe>'
      }, filterProperty: 'adFormatsOid.adsOid.formKey.form_name', disableSort: true},
    { prop: 'description', type: 'virtual', title: 'Beschreibung', renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['ad_formats_oid'].resolved['ads_oid'].data["description"]) {
            // @ts-ignore
            return record.resolved['ad_formats_oid'].resolved['ads_oid'].data["description"]
          }
        } catch (e) {
          return 'Seitenformat'
        }
        return '<keine Angabe>'
      }, filterProperty: 'adFormatsOid.adsOid.description', disableSort: true },
    { prop: 'cost_value', type: 'int', title: 'Preis' },
    { prop: 'bleed', type: 'virtual', subType: 'bool', disableSearch: true, title: 'Anschnitt', renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['ad_formats_oid'].resolved['ads_oid'].data.hasOwnProperty("bleed")) {
            // @ts-ignore
            return record.resolved['ad_formats_oid'].resolved['ads_oid'].data["bleed"] ? 'Ja' : 'Nein'
          }
        } catch (e) {
          return '<keine Angabe1>'
        }
        return '<keine Angabe2>'
      }, disableSort: true },
  ]
}
