import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndexSignature, ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "../../models/main";

@Component({
  selector: 'wefra-abstract-record-view',
  templateUrl: './view.component.html',
})
export class AbstractRecordsViewComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {
  @Input({ required: true }) public record?: T
  @Input({ required: true }) public modelConfig?: ModelConfigurationInterface

  @Output("closeClick") closeClickEvent = new EventEmitter<boolean>()

  ngOnInit(): void {

    if (! this.modelConfig) return
    if (! this.record) return

    let controls: IndexSignature = {}
    for (const field of this.modelConfig.fields) {
      let control = [ this.record.data[field.prop] ?? undefined ]
      if (field.validators) {
        control.push(field.validators)
      }
      controls[field.prop] = control
    }

    let options: IndexSignature = {}
    if (this.modelConfig.validators) {
      options["validators"] = this.modelConfig.validators
    }
  }
  onClose() {
    this.closeClickEvent.emit(true)
  }
}
