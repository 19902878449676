import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface PriceTypeRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
}

export interface PriceTypeRecordInterface extends RecordInterface<PriceTypeRecordDataInterface> {

}

export const PriceTypeModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "price_types",
  allowExportXlsx: true,
  title: "Preistypen",
  titleSingular: "Preistyp",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
  ]
}

