import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  WasmuthFktAdspecialCostTypeRecordDataInterface,
  WasmuthFktAdspecialCostTypeRecordInterface
} from "../../models/wasmuth_fkt_adspecial_cost_type";

@Injectable()
export class HttpWasmuthFktAdspecialCostTypeService extends HttpAbstractRecordService<WasmuthFktAdspecialCostTypeRecordInterface, WasmuthFktAdspecialCostTypeRecordDataInterface> {
  slug: ServiceSlugProperty = 'wasmuth_fkt_adspecial_cost_types'
}
