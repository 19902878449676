import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthRequestInterface } from "../models/auth";
import { AuthService } from "../services/auth.service";
import { ToastService } from "../services/toast.service";

@Component({
  selector: 'wefra-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {

  form: FormGroup
  fb: FormBuilder

  constructor(
    @Inject(AuthService) public authService: AuthService,
    @Inject(ToastService) public toastService: ToastService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      email: ["", { validators: [Validators.email] }],
      password: ["", { validators: [Validators.required] }]
    })
    this.authService = authService
    this.fb = fb

    this.toastService = toastService
  }

  doLogin(formValues: AuthRequestInterface) {
    this.authService.login(formValues).subscribe(response => {
      this.authService.userSubject.next(response)
      this.toastService.showSuccess('Sie sind mit dem Benutzer ' + response.user.data.email + ' angemeldet!')
    }, error => {
      if (error.status === 400) {
        this.toastService.showError(error.error.message)
      } else {
        this.toastService.showError('Die Anmeldung war leider nicht erfolgreich.')
      }
    })
  }
}
