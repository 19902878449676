import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {
  platformId : Object
  defaultTtl: number = 3600
  clientAppVersion: string
  knownClientAppVersion: string | null = '0.0.0'

  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId
    this.clientAppVersion = environment.clientAppVersion

    if (isPlatformBrowser(this.platformId)) {
      if (this.clientAppVersion != this.getKnownClientVersion()) {
        // TODO: migrate data from localstorage to apply models on version change
        this.clear()
        this.setKnownClientVersion()
      }
    }
  }

  get(key: string) {
    if (isPlatformServer(this.platformId)) return null
    const c = localStorage.getItem(key)
    if (! c) return null
    const data = JSON.parse(c)

    // silent migrate if no ttl is set
    if (! data.hasOwnProperty("validUntil")) {
      console.log('silently migrate localstorage ...')
      this.set(key, data)
      return data
    }
    let unixTime = Date.now()
    if (unixTime > data.validUntil) {
      console.log('ttl reached, remove data for key ' + key + ' ...')
      this.remove(key)
      return null
    }

    if (data.updateTtlOnRead) {
      // renew validUntil
      this.set(key, data.data, data.ttl)
    }

    return data.data
  }

  set(key: string, value: any, ttl: number = this.defaultTtl, updateTtlOnRead: boolean = true) {
    if (isPlatformServer(this.platformId)) return
    let validUntil = 0
    if (ttl) {
      validUntil = Date.now() + ttl * 1000 // millis
    }
    const v = {ttl: ttl, validUntil: validUntil, data: value, updateTtlOnRead: updateTtlOnRead }

    localStorage.setItem(key, JSON.stringify(v))
  }

  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) localStorage.removeItem(key)
  }

  clear() {
    if (isPlatformBrowser(this.platformId)) localStorage.clear()
  }

  keys() {
    if (isPlatformBrowser(this.platformId)) return localStorage["keys"]
    return []
  }

  getKnownClientVersion(): string | null {
    if (isPlatformBrowser(this.platformId)) return localStorage.getItem('version-client')
    return environment.clientAppVersion
  }

  setKnownClientVersion() {
    if (isPlatformBrowser(this.platformId)) localStorage.setItem('version-client', environment.clientAppVersion)
  }
}
